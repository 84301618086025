import {NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {MenuItem, MessageService} from "primeng/api";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {Button} from "primeng/button";
import {CarouselModule} from "primeng/carousel";
import {DividerModule} from "primeng/divider";
import {DropdownModule} from "primeng/dropdown";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputNumberModule} from "primeng/inputnumber";
import {Ripple} from "primeng/ripple";
import metadata from "../../constants/metadata";
import {GenericLookupDTO} from "../../models/dtos/lookup.dto";
import {RangedProduct} from "../../models/dtos/typed-product.dto";
import {ProductDTO} from "../../models/dtos/product.dto";
import {BasketService} from "../../services/basket.service";
import {ProductService} from "../../services/product.service";
import {UnderlayAddonComponent} from "./components/underlay-addon/underlay-addon.component";

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [
    BreadcrumbModule,
    NgIf,
    NgForOf,
    CarouselModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    FloatLabelModule,
    Button,
    Ripple,
    UnderlayAddonComponent,
    DividerModule,
  ],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent implements OnInit {
  breadcrumbItems: MenuItem[] | undefined;
  home: MenuItem | undefined;

  selectedProduct: ProductDTO | undefined;
  rangedProduct: RangedProduct | undefined;

  availableSizes: Array<GenericLookupDTO> = [];
  selectedSize: string | null = null;
  quantity = 1;
  total = '';

  responsiveOptions = [];

  constructor(public basketService: BasketService,
              public productService: ProductService,
              private route: ActivatedRoute,
              private messageService: MessageService) {
    route.url.subscribe(val => {
      this.onLoad()
    });
  }

  ngOnInit() {
    this.onLoad()
  }

  onLoad() {
    this.initialiseProducts();
    this.initialiseBreadcrumb();
    this.getSizeOptions();

    this.responsiveOptions = [
      {
        breakpoint: '767px',
        numVisible: 3,
        numScroll: 1
      }
    ];
  }

  initialiseProducts() {
    this.selectedProduct = this.productService.getProductById(this.route.snapshot.url[this.route.snapshot.url.length - 1].toString())

    if (this.selectedProduct) {
      this.selectedSize = this.selectedProduct.metadata.size;
      this.total = this.productService.getPrice(this.selectedProduct);
      this.rangedProduct = this.productService.getRangedProductByProductId(this.selectedProduct.id)
    }
  }

  initialiseBreadcrumb() {
    this.breadcrumbItems = [
      { label: 'Shop', routerLink: ['/shop'] },
      { label: this.selectedProduct?.metadata.type, routerLink: ['/shop', this.selectedProduct?.metadata.type] },
      { label: this.selectedProduct?.name + ' ' + (this.selectedProduct?.metadata?.colour ?? '') },
    ];

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

  handleColourClick(product: ProductDTO) {
    this.selectedProduct = product;
    this.selectedSize = this.selectedProduct.metadata.size;

    this.getSizeOptions();
  }

  getSizeOptions() {
    const colouredProduct = this.rangedProduct?.colours?.find(x => x.colour === this.selectedProduct?.metadata?.colour);

    if (colouredProduct) {
      this.availableSizes = colouredProduct?.sizes.map(x => ({ name: x.size }));
    }
  }

  handleSizeSelect() {
    this.selectedProduct = this.rangedProduct.colours
      .find(x => x.colour === this.selectedProduct.metadata.colour).sizes
      .find(x => x.size === this.selectedSize).product;

    this.quantity = 1;
    this.calculateTotal();
  }

  handleQuantityChange() {
    this.calculateTotal()
  }

  calculateTotal() {
    // @ts-ignore
    this.total = (this.selectedProduct?.default_price?.unit_amount * this.quantity / 100).toFixed(2);

  }

  addToBasket() {
    if (!this.selectedProduct) {
      return;
    }

    this.basketService.addToBasket({ product: this.selectedProduct, quantity: this.quantity }).subscribe(res => {
      this.basketService.basket = res;
      this.basketService.loading = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product added to basket.' })
    })
  }

  protected readonly metadata = metadata;
}
