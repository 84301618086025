<div id="dialog-container">
  <form>
    <div>
      <label for="name">Name</label>
      <input pInputText name="name" id="name" autocomplete="off" [(ngModel)]="user.name" />
    </div>

    <div>
      <label for="email">Email</label>
      <input pInputText name="email" id="email" autocomplete="off" [(ngModel)]="user.email" />
    </div>

    <div *ngIf="!editing">
      <label for="password">Password</label>
      <input pInputText name="password" id="password" autocomplete="off" type="password" [(ngModel)]="password" />
    </div>

    <div>
      <label for="role">Role</label>
      <p-dropdown
        id="role"
        name="role"
        [options]="lookupService.lookups.userRoles"
        [(ngModel)]="user.roleId"
        optionLabel="name"
        optionValue="id"></p-dropdown>
    </div>
  </form>

  <div class="button-section">
    <p-button label="Save" severity="success" [loading]="loading" (click)="save()" />
  </div>
</div>
