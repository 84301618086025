import {CommonModule} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {MessageService} from "primeng/api";
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {MessageModule} from "primeng/message";
import {DialogModule} from "primeng/dialog";
import {ToastModule} from "primeng/toast";
import {Subscription} from "rxjs";
import {cookieConfig} from "./constants/cookie-consent";
import {BasketService} from "./services/basket.service";
import {LookupService} from "./services/lookup.service";
import {ProductService} from "./services/product.service";
import {FooterComponent} from "./shared/footer/footer.component";
import {HeaderComponent} from "./shared/header/header.component";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
  NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent,
  WindowService
} from 'ngx-cookieconsent';
import {PageLoaderComponent} from "./shared/page-loader/page-loader.component";

@Component({
  selector: 'app-root',
  standalone: true,
  providers: [
    MessageService,
    DialogService,
    NgcCookieConsentService,
    WindowService,
    NgcCookieConsentConfig
  ],
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    CommonModule,
    ToastModule,
    MessageModule,
    DialogModule,
    DynamicDialogModule,
    NgcCookieConsentModule,
    PageLoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  loading = true;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(private basketService: BasketService,
              private productService: ProductService,
              private lookupService: LookupService,
              private route: ActivatedRoute,
              private router: Router,
              private cookieService: NgcCookieConsentService
              ) {}

  ngOnInit() {
    this.onLoad()

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.initializingSubscription = this.cookieService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      });

    this.initializedSubscription = this.cookieService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.cookieService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize...
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  async onLoad() {
    this.productService.getProducts().subscribe(res => {
      this.productService.products = res;

      this.productService.getTypedProducts().subscribe(res => {
        this.productService.typedProducts = res;

        this.lookupService.getLookups().subscribe(res => {
          this.lookupService.lookups = res;

          this.basketService.getBasket().subscribe(res => {
            this.basketService.basket = res;
            localStorage.setItem('basket', res.id)
            this.basketService.loading = false;
          })

          this.loading = false;
        })
      })
    })
  }

  renderFooter() {
    return !['/dashboard'].includes(this.router.url)
  }
}
