import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideRouter, Router} from '@angular/router';
import {provideNgcCookieConsent} from "ngx-cookieconsent";
import {environment} from "../environments/environment";
import { routes } from './app.routes';
import {cookieConfig} from "./constants/cookie-consent";
import {BaseUrlInterceptor} from "./interceptors/base-url.interceptor";
import * as Sentry from "@sentry/angular";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideNgcCookieConsent(cookieConfig),

    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },

    // Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

    // Environment Variables
    {
      provide: "API_URL", useValue: environment.apiUrl
    }


  ]
};
