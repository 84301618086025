import { Component } from '@angular/core';
import metadata from "../../constants/metadata";

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

  protected readonly metadata = metadata;
}
