<p-table
  #directoryTable
  [value]="productService.products"
  [tableStyle]="{ 'width': '100%' }"
  [paginator]="true"
  [rows]="50"
  [rowsPerPageOptions]="[20, 50, 100]"
  [globalFilterFields]="['name', 'metadata.range', 'metadata.colour', 'metadata.type', 'id', 'metadata.size']"
>
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <p-iconField iconPosition="left" class="ml-auto">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input
          pInputText
          type="text"
          (input)="directoryTable.filterGlobal($event.target?.value, 'contains')"
          placeholder="Search keyword" />
      </p-iconField>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Type</th>
      <th>Range</th>
      <th>Colour</th>
      <th>Size</th>
      <th>Price</th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr>
      <td>{{ product.id }}</td>
      <td>{{ product.name }} {{ product.metadata.colour }}</td>
      <td>{{ product.metadata.type }}</td>
      <td>{{ product.metadata.range }}</td>
      <td>{{ product.metadata.colour }}</td>
      <td>{{ product.metadata.size }}</td>
      <td>{{ (product.default_price.unit_amount / 100) | currency: 'GBP' }} </td>
      <td>
        <p-button styleClass="p-button-text p-button-sm" icon="pi pi-eye" (click)="onViewClick(product)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
