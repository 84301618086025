import {NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {Button} from "primeng/button";
import {FileUploadModule} from "primeng/fileupload";
import {ColouredProduct, RangedProduct, TypedProduct} from "../../models/dtos/typed-product.dto";
import {ProductType} from "../../models/dtos/product.dto";
import {AdminService} from "../../services/admin.service";
import {ApplicationStateService} from "../../services/application-state.service";
import {LookupService} from "../../services/lookup.service";
import {ProductService} from "../../services/product.service";
import capitalize from "../../utils/capitalize";
import {AdminTypeBarComponent} from "../admin-type-bar/admin-type-bar.component";

@Component({
  selector: 'app-admin-image-uploader',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    Button,
    FileUploadModule,
    AdminTypeBarComponent
  ],
  templateUrl: './admin-image-uploader.component.html',
  styleUrl: './admin-image-uploader.component.scss'
})
export class AdminImageUploaderComponent implements OnInit {
  protected readonly capitalize = capitalize;

  rangedProducts: Array<RangedProduct> = [];
  loading = false;

  constructor(private applicationState: ApplicationStateService,
              public adminService: AdminService,
              public productService: ProductService,
              private lookupService: LookupService
  ) {}

  ngOnInit() {
    const type = this.lookupService.lookups.productTypes[this.applicationState.admin_active_type_tab].name as ProductType;
    this.rangedProducts = this.productService.getTypedProduct(type).ranges;
  }

  getProductIds(colouredProduct: ColouredProduct) {
    return colouredProduct.sizes.map(x => x.product).map(x => x.id);
  }

  updateProductImages(event: any, productIds: Array<string>) {
    this.loading = true;

    this.adminService.updateProductImages(event, productIds).subscribe(res => {
      this.productService.getProducts().subscribe(res => {
        this.productService.products = res;

        this.productService.getTypedProducts().subscribe(res => {
          this.productService.typedProducts = res;

          this.ngOnInit();
          this.loading = false;
        })
      })
    })
  }
}
