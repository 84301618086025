<div id="product-card" *ngIf="selectedProduct">
  <div class="image-container">
    <img [routerLink]="['/product', selectedProduct.id]" [src]="selectedProduct.images[0]" [alt]="selectedProduct.name" />
  </div>

  <p-carousel
    *ngIf="checkShowColours()"
    [value]="rangedProduct ? rangedProduct.colours : []"
    [numVisible]="3"
    [circular]="true"
    [showIndicators]="false"
    [numScroll]="1"
    [responsiveOptions]="responsiveOptions">
    <ng-template let-colouredProduct pTemplate="item">
      <img
        class="colour-selector"
        [alt]="selectedProduct.name"
        [src]="colouredProduct?.sizes?.[0]?.product?.images?.[0]"
        (click)="handleColourClick(colouredProduct?.sizes?.[0].product)"
      />
    </ng-template>
  </p-carousel>

  <div class="content-container">
    <div>
      <p>{{ capitalize(selectedProduct.name) }} {{ capitalize(selectedProduct.metadata.colour) }}</p>
      <h3><span>£</span>{{ productService.getPrice(selectedProduct) }}</h3>
    </div>

    <p-button icon="pi pi-chevron-right" iconPos="right" [style]="{ width: '100%' }" [rounded]="true" [routerLink]="['/product', selectedProduct.id]" label="Details" severity="danger"></p-button>
  </div>
</div>
