<app-admin-login *ngIf="!authService.user"></app-admin-login>

<div *ngIf="authService.user" id="dashboard">
  <div class="dashboard-admin-menu">
    <app-admin-menu></app-admin-menu>
  </div>

  <div class="dashboard-main-content">
    <app-admin-directory *ngIf="applicationState.admin_active_menu_item === 'Directory'"></app-admin-directory>
    <app-admin-image-uploader *ngIf="applicationState.admin_active_menu_item === 'Image Uploader'"></app-admin-image-uploader>
    <app-admin-size-explorer *ngIf="applicationState.admin_active_menu_item === 'Size Explorer'"></app-admin-size-explorer>
    <app-admin-import *ngIf="applicationState.admin_active_menu_item === 'Import'"></app-admin-import>
    <app-admin-orders *ngIf="applicationState.admin_active_menu_item === 'Orders'"></app-admin-orders>
    <app-admin-users *ngIf="applicationState.admin_active_menu_item === 'Users'"></app-admin-users>
    <app-admin-development-panel *ngIf="applicationState.admin_active_menu_item === 'Development Panel'"></app-admin-development-panel>

  </div>
</div>
