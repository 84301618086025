const metadata = {
  email: 'hello@rollendshop.co.uk',
  tel: '441274981057',
  telSpaced: '+44 1274 981057',
  address: 'RollEndShop.co.uk, Forster Square Retail Park, 60 Valley Road, BD1 4AA',
  srAddress: 'SR Carpets and Floors, 5 Robert Street, BD3 9QF',
  facebook: 'http://www.facebook.com/Buycarpets/',
  placeholder: 'https://res.cloudinary.com/dliog6kq6/image/upload/v1717838456/rollendshop/placeholder_uz61wy.jpg',
  mapsLink: 'https://www.google.com/maps?ll=53.80109223230436,-1.754177265458076',
  srMapsLink: 'https://www.google.com/maps?ll=53.79251199189065,-1.740279778900025'
}

export default metadata;
