export default function capitalize(text: string) {
    if (!text) return "";

    const spacedText = capitalizeSpacedText(text);
    const slashedText = capitalizeSlashedText(spacedText)

    return `${slashedText?.substring(0, 1)?.toUpperCase()}${slashedText?.substring(1, slashedText.length)}`
}

function capitalizeSpacedText(input: string) {
    return input.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

function capitalizeSlashedText(input: string) {
    return input.split('/').map(color => capitalizeSpacedText(color)).join('/');
}