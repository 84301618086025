

<div id="product-details" *ngIf="selectedProduct">
  <p-breadcrumb
    class="max-w-full"
    [model]="breadcrumbItems"
    [home]="home" />

  <div class="product">
    <div class="details-container">
      <div class="image-container">
        <img [src]="selectedProduct.images[0]" [alt]="selectedProduct.name" />
      </div>

      <div class="description-container">
        <h3>Description</h3>
        <p>{{ selectedProduct?.description }}</p>
      </div>

      <div class="delivery-container">
        <h3>Home Delivery for £20.00</h3>
        <p>We deliver nationwide. Choose home delivery at the checkout to have your carpet delivered straight to your door.</p>

        <h3>Collect in Store for FREE</h3>
        <p>Collect your carpet during working days and hours for free from our store located at <strong>{{ metadata.address }}</strong>. Please call before you arrange your visit to make sure your flooring is ready for collection..</p>

        <p>*Orders available for collection after 7-14 working days. Exceptions may apply.</p>
      </div>
    </div>

    <div class="actions-container">
      <div class="product-information">
        <h1>{{ selectedProduct.name }} {{ selectedProduct.metadata.colour }}</h1>
        <h3><span>£</span>{{ productService.getPrice(selectedProduct) }}</h3>
      </div>

      <p-divider></p-divider>

      <p-carousel
        [value]="rangedProduct ? rangedProduct.colours : []"
        [numVisible]="7"
        [circular]="true"
        [numScroll]="1"
        [responsiveOptions]="responsiveOptions">
        <ng-template let-colouredProduct pTemplate="item">
          <img
            class="colour-selector"
            [alt]="selectedProduct.name"
            [src]="colouredProduct?.sizes?.[0]?.product?.images?.[0]"
            (click)="handleColourClick(colouredProduct?.sizes?.[0].product)"
          />
        </ng-template>
      </p-carousel>

      <div class="inputs">
        <div>
          <p-floatLabel>
            <p-dropdown
              [options]="availableSizes"
              [(ngModel)]="selectedSize"
              (ngModelChange)="handleSizeSelect()"
              optionLabel="name"
              optionValue="name"
              placeholder="Size"
              [style]="{'width':'100%'}"
            ></p-dropdown>
            <label>Size</label>
          </p-floatLabel>
        </div>

        <div>
          <p-floatLabel>
            <p-inputNumber
              inputId="integeronly"
              [(ngModel)]="quantity"
              (ngModelChange)="handleQuantityChange()"
              [style]="{'width':'100%'}"
              [min]="1"
            />
            <label>Quantity</label>
          </p-floatLabel>
        </div>
      </div>

      <p-divider></p-divider>

      <app-underlay-addon></app-underlay-addon>

      <div class="pricing-container">
        <div>
          <p>Total</p>
          <h3><span>£</span>{{ total }}</h3>
        </div>

        <p-button
          [rounded]="true"
          (click)="addToBasket()"
          icon="pi pi-shopping-bag"
          label="Add to Basket"
          severity="danger"
          [disabled]="basketService.loading">
        </p-button>
      </div>
    </div>
  </div>
</div>
