<p-table
  #importTable
  [value]="importedProducts"
  [tableStyle]="{ 'width': '100%' }"
  [paginator]="true"
  [rows]="50"
  [rowsPerPageOptions]="[20, 50, 100]"
  [globalFilterFields]="['name', 'metadata.range', 'metadata.colour', 'metadata.type', 'id', 'metadata.size']"
>
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <p-iconField iconPosition="left" class="ml-auto">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input
          pInputText
          type="text"
          (input)="importTable.filterGlobal($event.target?.value, 'contains')"
          placeholder="Search keyword" />
      </p-iconField>

      <div>
        <p-fileUpload
          mode="basic"
          chooseLabel="Import"
          chooseIcon="pi pi-upload"
          name="import"
          [customUpload]="true"
          (uploadHandler)="importProducts($event)"
          [auto]="true"
          maxFileSize="1000000"
          [disabled]="loading"
        ></p-fileUpload>

        <p-button
          icon="pi pi-cloud-download"
          styleClass="p-button-text"
          [loading]="loading"
          (click)="onDownloadTemplateClick()"
        ></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Type</th>
      <th>Range</th>
      <th>Colour</th>
      <th>Size</th>
      <th>Price</th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr>
      <td>{{ product.id }}</td>
      <td>{{ product.name }} {{ product.metadata.colour }}</td>
      <td>{{ product.metadata.type }}</td>
      <td>{{ product.metadata.range }}</td>
      <td>{{ product.metadata.colour }}</td>
      <td>{{ product.metadata.size }}</td>
      <td>{{ (product.default_price.unit_amount / 100) | currency: 'GBP' }} </td>
      <td>
        <p-button styleClass="p-button-text p-button-sm" icon="pi pi-eye" (click)="onViewClick(product)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
