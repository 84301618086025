<div id="product-filters">
  <p-dropdown
    [options]="lookupService.lookups.productTypes"
    [(ngModel)]="applicationState.product_filter_type"
    (ngModelChange)="handleFilterChange()"
    [showClear]="true"
    optionLabel="name"
    optionValue="name"
    placeholder="Type"
    [style]="{'width':'100%'}"
  ></p-dropdown>

  <p-dropdown
    [options]="lookupService.lookups.productRanges"
    [(ngModel)]="applicationState.product_filter_range"
    (ngModelChange)="handleFilterChange()"
    [showClear]="true"
    optionLabel="name"
    optionValue="name"
    placeholder="Range"
    [style]="{'width':'100%'}"
  ></p-dropdown>

  <p-dropdown
    [options]="lookupService.lookups.productColours"
    [(ngModel)]="applicationState.product_filter_colour"
    (ngModelChange)="handleFilterChange()"
    [showClear]="true"
    optionLabel="name"
    optionValue="name"
    placeholder="Colour"
    [style]="{'width':'100%'}"
  ></p-dropdown>

  <p-dropdown
    [options]="lookupService.lookups.productSizes"
    [(ngModel)]="applicationState.product_filter_size"
    (ngModelChange)="handleFilterChange()"
    [showClear]="true"
    optionLabel="name"
    optionValue="name"
    placeholder="Size"
    [style]="{'width':'100%'}"
  ></p-dropdown>
</div>
