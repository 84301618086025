<div id="basket-product">
  <div class="details-container">
    <img [routerLink]="['/product', basketProduct.product.id]" [src]="basketProduct.product.images[0]" [alt]="basketProduct.product.name" />

    <div>
      <p>{{ basketProduct.product.name }} {{ basketProduct.product.metadata.colour }}</p>

      <p class="small-detail">Size: {{ basketProduct.product.metadata.size }}</p>
      <p class="small-detail">Subtotal: £{{ getSubtotal() }}</p>
    </div>
  </div>

  <div>
    <p class="small-detail">Quantity: {{ basketProduct.quantity }}</p>
    <p-button *ngIf="actions" styleClass="p-button-text p-button-sm" class="small-detail" [disabled]="basketService.loading" (click)="removeFromBasket()">Remove</p-button>
  </div>
</div>
