<div id="product-list">
  <p-divider align="center">
    <b>
      <app-heading [text]="header"></app-heading>
    </b>
  </p-divider>

  <app-product-filters (onFilterChange)="handleFilterChange()"></app-product-filters>

  <div>
    <span class="product-count">
      <i class="pi pi-bars"></i>
      <p>{{ filterService.filteredProducts.length }} items`                             </p>
    </span>
    <div class="product-grid">
      <app-product-card
        *ngFor="let colouredProduct of paginatedProducts"
        [colouredProduct]="colouredProduct">
      </app-product-card>
    </div>

    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="first"
      [rows]="productsPerPage"
      [totalRecords]="filterService.filteredProducts.length"
      [rowsPerPageOptions]="[10, 20, 30]" />
  </div>
</div>
