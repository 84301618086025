import {ProductType} from "./product.dto";

export class LookupDTO {
  productColours: Array<ProductTypeLookupDTO>;
  productRanges: Array<ProductTypeImageLookupDTO>;
  productSizes: Array<ProductTypeLookupDTO>;
  productTypes: Array<GenericLookupDTO>;
  orderStatuses: Array<GenericLookupDTO>;
  orderTypes: Array<GenericLookupDTO>;
  userRoles: Array<GenericLookupDTO>;
}

export class GenericLookupDTO {
  id?: number;
  name!: string;
}

export class ProductTypeLookupDTO extends GenericLookupDTO {
  type!: ProductType;
}

export class ProductTypeImageLookupDTO extends ProductTypeLookupDTO {
  image!: string;
}
