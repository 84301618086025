import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ProductSpotlightDTO} from "../models/dtos/product-spotlight.dto";
import {TypedProduct} from "../models/dtos/typed-product.dto";
import {ProductDTO, ProductType} from "../models/dtos/product.dto";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  products: Array<ProductDTO> = [];
  typedProducts: Array<TypedProduct> = [];

  constructor(private http: HttpClient) { }

  getProducts(): Observable<Array<ProductDTO>> {
    return this.http.get<Array<ProductDTO>>('product')
  }

  getTypedProducts(): Observable<Array<TypedProduct>> {
    return this.http.get<Array<TypedProduct>>('product/typed')
  }

  getProductsByType(type: ProductType): Array<ProductDTO> {
    return this.products.filter(x => x.metadata.type.toLowerCase() === type.toLowerCase());
  }

  getProductById(id: string) {
    return this.products.find(x => x.id === id);
  }

  getTypedProduct(type: ProductType) {
    return this.typedProducts.find(x => x.type === type);
  }

  getRangedProductByProductId(id: string) {
    return this.typedProducts
      .flatMap(x => x.ranges)
      .find(x =>
        x.colours
          .flatMap(x => x.sizes)
          .flatMap(x => x.product).map(x => x.id)
          .includes(id)
    )
  }

  getPrice(product: ProductDTO) {
    // @ts-ignore
    return (product.default_price.unit_amount / 100).toFixed(2)
  }

  getSpotlightedProducts() {
    return this.http.get<ProductSpotlightDTO>('product/spotlight')
  }

  getColouredProducts() {
    return this.typedProducts.flatMap(x => x.ranges).flatMap(x => x.colours);
  }

  getColouredProductByProduct(product: ProductDTO) {
    const colouredProducts = this.getColouredProducts();

    const colouredProduct = colouredProducts.find(colouredProduct =>
      colouredProduct.sizes.some(size => size.product.id === product.id)
    );

    if (colouredProduct) {
      const index = colouredProduct.sizes.findIndex(size => size.product.id === product.id);
      if (index !== -1) {
        const [size] = colouredProduct.sizes.splice(index, 1);
        colouredProduct.sizes.unshift(size);
      }
    }

    return colouredProduct;
  }
}
