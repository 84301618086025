import {NgForOf} from "@angular/common";
import { Component } from '@angular/core';
import {GoogleMap} from "@angular/google-maps";
import metadata from "../../constants/metadata";
import {HeaderAddressComponent} from "../../shared/header/components/header-address/header-address.component";
import {HeadingComponent} from "../../shared/heading/heading.component";
import capitalize from "../../utils/capitalize";

@Component({
  selector: 'app-location',
  standalone: true,
  imports: [
    GoogleMap,
    HeaderAddressComponent,
    HeadingComponent,
    NgForOf
  ],
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss'
})
export class LocationComponent {
  zoom = 18;
  center: google.maps.LatLngLiteral = {
    lat: 53.80109223230436, lng: -1.754177265458076
  };
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
  };

  openingTimes = [
    { day: 'mon', open: '09:00', close: '18:00'  },
    { day: 'tues', open: '09:00', close: '18:00'  },
    { day: 'wed', open: '09:00', close: '18:00'  },
    { day: 'thurs', open: '09:00', close: '18:00'  },
    { day: 'fri', open: '09:00', close: '18:00'  },
    { day: 'sat', open: '09:00', close: '18:00'  },
  ]

  protected readonly metadata = metadata;
  protected readonly open = open;
  protected readonly capitalize = capitalize;
}
