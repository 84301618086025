<div id="admin-menu">
  <div *ngFor="let section of getAllowedSections(this.menu.sections)" class="menu-section">
    <h3>
      <i class="pi" [ngClass]="section.icon"></i>
      {{ section.label }}
    </h3>

    <ul>
      <li *ngFor="let item of getAllowedItems(section.items)"
          (click)="!item.disabled ? applicationState.admin_active_menu_item = item.label : null"
          [ngClass]="{ 'disabled': item.disabled }"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</div>
