import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-header-address',
  standalone: true,
  imports: [],
  templateUrl: './header-address.component.html',
  styleUrl: './header-address.component.scss'
})
export class HeaderAddressComponent {
  @Input() address!: string;
}
