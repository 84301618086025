<div id="header-product-search">
  <p-autoComplete
    class="inputSearch"
    [(ngModel)]="dropdownProduct"
    [suggestions]="suggestions"
    (completeMethod)="searchProducts($event)"
    optionLabel="label"
    placeholder="Search"
    [style]="{'width':'95%'}" [inputStyle]="{'width': '100%'}"
    (onSelect)="onSelect($event)"
  >
    <ng-template let-dropdownProduct pTemplate="item">
      <div class="dropdown-item">
        <img
          [src]="dropdownProduct.product.images[0]"/>
        <div>{{ dropdownProduct.product.name }} {{ dropdownProduct.product.metadata.colour }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
