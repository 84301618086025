import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";
import {BasketDTO, BasketProductDTO} from "../models/dtos/basket.dto";
import {ProductDTO} from "../models/dtos/product.dto";

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  basket!: BasketDTO;
  loading = false;

  constructor(private http: HttpClient) {}

  getBasket() {
    this.loading = true;
    const id = localStorage.getItem('basket');

    if (!id) {
      return this.http.post<BasketDTO>('basket', {})
    }
    else {
      return this.http.get<BasketDTO>(`basket/${id}`)
    }
  }

  updateBasket(basket: BasketDTO) {
    this.loading = true;

    return this.http.put<BasketDTO>('basket', basket)
  }

  getNewBasket() {
    this.loading = true;
    localStorage.removeItem('basket');

    return this.http.post<BasketDTO>('basket', {})
  }

  addToBasket(basketProduct: BasketProductDTO) {
    const existingIndex = this.basket.products.findIndex(x => x.product.id === basketProduct.product.id);

    if (existingIndex > -1) {
      const existingItem = this.basket.products[existingIndex];
      this.basket.products[existingIndex] = {...basketProduct, quantity: basketProduct.quantity + existingItem.quantity };
    }
    else {
      this.basket.products.push(basketProduct);
    }

    return this.updateBasket(this.basket);
  }

  removeFromBasket(basketProduct: BasketProductDTO) {
    this.basket.products = this.basket.products.filter(x => x.product.id !== basketProduct.product.id);

    return this.updateBasket(this.basket)
  }

  getSubtotal() {
    const prices = this.basket?.products.map(product => product.product.default_price.unit_amount)

    if (!prices) {
      return (0).toFixed(2);
    }

    let total = 0;
    for (let price of prices) {
      if (!price) {
        break;
      }

      total = total + price;
    }

    return (total / 100).toFixed(2);
  }
}
