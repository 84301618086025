<div id="order-success">
  <div class="order-details-container">
    <app-heading text="Thank you for your order!"></app-heading>

    <div>
      <p>Thanks for placing order <strong>{{ order.id }}</strong>.</p>
      <p>Please check your emails for a confirmation.</p>

      <p>If you have any questions or queries then feel free to get in contact with us.</p>
    </div>
  </div>

  <div class="product-container">
    <h3>Your Order</h3>

    <app-basket-product *ngFor="let basketProduct of order.products" [basketProduct]="basketProduct" [actions]="false"></app-basket-product>

    <h3>Subtotal: £{{ getSubtotal() }}</h3>
  </div>
</div>



