import {NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {ProductTypeImageLookupDTO} from "../../models/dtos/lookup.dto";
import {LookupService} from "../../services/lookup.service";
import {HeadingComponent} from "../../shared/heading/heading.component";
import {RangeCardComponent} from "../../shared/range-card/range-card.component";

@Component({
  selector: 'app-carpet-ranges',
  standalone: true,
  imports: [
    NgIf,
    RangeCardComponent,
    NgForOf,
    HeadingComponent
  ],
  templateUrl: './carpet-ranges.component.html',
  styleUrl: './carpet-ranges.component.scss'
})
export class CarpetRangesComponent implements OnInit {
  carpetRanges: Array<ProductTypeImageLookupDTO> = [];

  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.carpetRanges = this.lookupService.lookups.productRanges.filter(x => x.type === 'Carpet');
  }
}
