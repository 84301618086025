<div class="header-dropdowns-container">
  <ul>
    <li *ngFor="let dropdown of dropdowns"
        (mouseenter)="handleDropdownHover(dropdown)"
        (click)="handleTypeClick(dropdown.type)"
        [routerLink]="['/shop', dropdown.type]"
    >
      <p>{{ dropdown.type }}</p>
    </li>
  </ul>

  <div *ngIf="applicationState.active_dropdown" class="dropdown-content" (mouseleave)="handleDropdownHover(null)">
    <div class="dropdown-routing">
      <div class="dropdown-column">
        <div *ngIf="applicationState.active_dropdown.ranges.length > 0" class="dropdown-section">
          <h2>Shop by range</h2>

          <a
            *ngFor="let range of applicationState.active_dropdown.ranges"
            (click)="handleRangeClick(range.name)"
            [routerLink]="['/shop', applicationState.active_dropdown.type, range.name]"
            class="dropdown-route">
            {{ capitalize(range.name) }}
          </a>
        </div>

        <div *ngIf="applicationState.active_dropdown.sizes.length > 0" class="dropdown-section">
          <h2>Shop by size</h2>

          <div class="dropdown-section-grid">
            <a
              *ngFor="let size of applicationState.active_dropdown.sizes"
              (click)="handleSizeClick(size.name)"
              [routerLink]="['/shop', applicationState.active_dropdown.type]"
              class="dropdown-route">
              {{ size.name }}
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="applicationState.active_dropdown.colours.length > 0" class="dropdown-section">
          <h2>Shop by colour</h2>

          <div class="dropdown-section-grid">
            <a
              *ngFor="let colour of applicationState.active_dropdown.colours"
              (click)="handleColourClick(colour.name)"
              [routerLink]="['/shop', applicationState.active_dropdown.type]"
              class="dropdown-route">
              {{ capitalize(colour.name) }}
            </a>
          </div>
      </div>
    </div>

    <p-divider layout="vertical"></p-divider>

    <div class="dropdown-showcase">
      <app-product-card
        [colouredProduct]="applicationState.active_dropdown.product"
      ></app-product-card>
    </div>
  </div>
</div>
