<app-admin-type-bar (onTabClick)="ngOnInit()"></app-admin-type-bar>

<div *ngFor="let rangedProduct of rangedProducts" id="admin-image-uploader">
  <h1>{{ capitalize(rangedProduct.range) }}</h1>

  <div class="product-range-container">
    <div *ngFor="let colouredProduct of rangedProduct.colours" class="product-colour-container">
      <p>{{ colouredProduct.colour ? capitalize(colouredProduct.colour) : colouredProduct.sizes[0].product.name }}</p>

      <p-fileUpload
        mode="basic"
        chooseLabel="Upload"
        chooseIcon="pi pi-upload"
        [name]="colouredProduct.name"
        accept="image/*"
        [multiple]="true"
        [customUpload]="true"
        (uploadHandler)="updateProductImages($event, getProductIds(colouredProduct))"
        [auto]="true"
        maxFileSize="1000000"
        [disabled]="loading"/>

      <div class="image-container">
        <img *ngFor="let image of colouredProduct.sizes[0].product.images" [src]="image" [alt]="image" />
      </div>
    </div>
  </div>
</div>
