import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {ProductSpotlightDTO} from "../../models/dtos/product-spotlight.dto";
import {ColouredProduct} from "../../models/dtos/typed-product.dto";
import {ProductService} from "../../services/product.service";
import {ProductCardComponent} from "../../shared/product-card/product-card.component";

@Component({
  selector: 'app-product-spotlight',
  standalone: true,
  imports: [
    NgIf,
    ProductCardComponent,
    NgForOf,
    NgClass
  ],
  templateUrl: './product-spotlight.component.html',
  styleUrl: './product-spotlight.component.scss'
})
export class ProductSpotlightComponent implements OnInit {
  tab = 0;

  mostPopular: Array<ColouredProduct> = [];
  topPicks: Array<ColouredProduct> = [];
  newStock: Array<ColouredProduct> = [];

  constructor(private productService: ProductService) {
  }

  ngOnInit() {
    this.productService.getSpotlightedProducts().subscribe(res => {
      this.mostPopular = res.mostPopular.map(x => this.productService.getColouredProductByProduct(x));
      this.topPicks = res.topPicks.map(x => this.productService.getColouredProductByProduct(x));
      this.newStock = res.newStock.map(x => this.productService.getColouredProductByProduct(x));
    })
  }

  protected readonly top = top;
}
