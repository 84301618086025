import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {LookupDTO} from "../models/dtos/lookup.dto";
import {ProductDTO, ProductType} from "../models/dtos/product.dto";

@Injectable({
  providedIn: 'root'
})
export class LookupService{
  lookups: LookupDTO = new LookupDTO();

  constructor(private http: HttpClient) { }

  getLookups(): Observable<LookupDTO> {
    return this.http.get<LookupDTO>('lookup')
  }

  getProductColoursByType(type: ProductType | null) {
    return this.lookups.productColours.filter(x => x.type === type);
  }

  getProductRangesByType(type: ProductType | null) {
    return this.lookups.productRanges.filter(x => x.type === type);
  }

  getProductSizesByType(type: ProductType | null) {
    return this.lookups.productSizes.filter(x => x.type === type);
  }

  getOrderStatus(id: number) {
    return this.lookups.orderStatuses.find(x => x.id === id);
  }

  getOrderType(id: number) {
    return this.lookups.orderTypes.find(x => x.id === id);
  }

  getUserRole(id: number) {
    return this.lookups.userRoles.find(x => x.id === id);
  }
}
