import {NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {Button} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {LoginDTO} from "../../models/dtos/login.dto";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-admin-login',
  standalone: true,
  imports: [
    InputTextModule,
    NgIf,
    PaginatorModule,
    Button,
    RouterLink
  ],
  templateUrl: './admin-login.component.html',
  styleUrl: './admin-login.component.scss'
})
export class AdminLoginComponent implements OnInit {
  credentials: LoginDTO = { email: '', password: '' }
  loading = false;
  error = '';

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.checkLocalToken();
  }

  login() {
    this.loading = true;

    this.authService.login(this.credentials).subscribe(
      data => {
        this.authService.parseToken(data.access_token);
        this.loading = false;
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
      }
    )
  }
}
