<div id="privacy-policy">
  <h1>Privacy Policy</h1>

  <p>This Privacy Policy describes how RollEndShop collects, uses, and discloses your personal information
    when you use our website and services.</p>

  <ol>
    <li>
      <h2>Information We Collect</h2>
      <p>When you use RollEndShop, we may collect the following types of information:</p>
      <ul>
        <li>Your name</li>
        <li>Contact information (email address, phone number, etc.)</li>
        <li>Payment information (credit card details, billing address, etc.)</li>
        <li>Information about your orders and transactions</li>
        <li>Device information (IP address, browser type, etc.)</li>
        <li>Cookies and similar tracking technologies</li>
      </ul>
    </li>

    <li>
      <h2>How We Use Your Information</h2>
      <p>We may use the information we collect for various purposes, including:</p>
      <ul>
        <li>Processing and fulfilling your orders</li>
        <li>Communicating with you about your orders, account, and promotions</li>
        <li>Improving our products and services</li>
        <li>Preventing fraudulent activities</li>
        <li>Customizing your shopping experience</li>
      </ul>
    </li>

    <li>
      <h2>Information Sharing</h2>
      <p>We may share your personal information with:</p>
      <ul>
        <li>Third-party service providers who assist us in providing our services</li>
        <li>Payment processors</li>
        <li>Legal authorities, if required by law or to protect our rights</li>
      </ul>
    </li>

    <li>
      <h2>Security</h2>
      <p>We take appropriate security measures to protect your personal information from unauthorized access,
        alteration, disclosure, or destruction.</p>
    </li>

    <li>
      <h2>Changes to this Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
        Privacy Policy on this page.</p>
    </li>

    <li>
      <h2>Contact Us</h2>
      <p>If you have any questions about our Privacy Policy, please contact us at {{ metadata.email }}</p>
    </li>
  </ol>
</div>
