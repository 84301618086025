import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() { }

  logEvent(event: string, tags?: Record<string, string>) {
    Sentry.metrics.increment(event, 1, tags);
  }

  logSet(event: string, type: string) {
    Sentry.metrics.set(event, type);
  }
}
