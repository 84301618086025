<div id="desktop-header">
  <div class="main-section">
    <img src="assets/logo_rectangle.png"
         alt="RollEndShop.co.uk"
         [routerLink]="['/']"
         (click)="applicationState.active_dropdown = null"
    />

    <div class="content-container">
      <div class="address-container">
        <app-header-address [address]="metadata.address"></app-header-address>
        <app-header-address [address]="metadata.srAddress"></app-header-address>
      </div>

      <div class="data-container">
        <div class="product-search-container">
          <app-header-product-search></app-header-product-search>
        </div>

        <a [href]="metadata.facebook" target="_blank"><i class="pi pi-facebook"></i></a>

        <a [routerLink]="['basket']" class="basket-button">
          <div *ngIf="basketService.basket?.products.length > 0" class="product-count-indicator">{{ basketService.basket?.products.length }}</div>
          <i class="pi pi-shopping-bag"></i>
        </a>

      </div>
    </div>
  </div>

  <div class="dropdown-section">
    <app-header-dropdowns></app-header-dropdowns>
  </div>

  <div class="contact-section">
    <a [href]="'tel:' + metadata.tel">
      <i class="pi pi-phone"></i>
      {{ metadata.telSpaced }}
    </a>
    <a [href]="'mailto:' + metadata.email">
      <i class="pi pi-envelope"></i>
      {{ metadata.email }}
    </a>
  </div>
</div>

<div id="mobile-header">
  <div class="main-section">
    <img src="assets/logo_rectangle.png"
         alt="RollEndShop.co.uk"
         [routerLink]="['/']"
         (click)="applicationState.active_dropdown = null"
    />

    <div class="data-container">
      <a [href]="metadata.facebook" target="_blank"><i class="pi pi-facebook"></i></a>

      <a [routerLink]="['basket']" class="basket-button">
        <div *ngIf="basketService.basket?.products.length > 0" class="product-count-indicator">{{ basketService.basket?.products.length }}</div>
        <i class="pi pi-shopping-bag"></i>
      </a>

      <a><i class="pi pi-bars" (click)="sidebarVisible = true"></i></a>
    </div>
  </div>

  <app-header-product-search></app-header-product-search>
</div>

<!--Mobile Sidebar-->
<p-sidebar [(visible)]="sidebarVisible">
  <ng-template pTemplate="header">
    RollEndShop.co.uk
  </ng-template>

  <div class="sidebar">
    <ul>
      <div class="section-header">Shop by Type</div>
      <li *ngFor="let typedSidebar of typedSidebars" (click)="openTypedSidebar(typedSidebar)">
        {{ typedSidebar.type }}
        <i class="pi pi-chevron-right"></i>
      </li>
    </ul>
  </div>
</p-sidebar>

<!--Mobile Type Sidebar-->
<p-sidebar [(visible)]="typedSidebarVisible" *ngIf="selectedTypedSidebar">
  <ng-template pTemplate="header">
    {{ selectedTypedSidebar.type }}
  </ng-template>
  <div class="sidebar">
    <ul>
      <div class="section-header">Shop by Range</div>
      <li *ngFor="let range of selectedTypedSidebar.ranges"
          (click)="handleRangeClick(range.name)"
          [routerLink]="['/shop', selectedTypedSidebar.type, range.name]">
        {{ range.name }}
      </li>
    </ul>

    <ul>
      <div class="section-header">Shop by Colour</div>
      <li *ngFor="let colour of selectedTypedSidebar.colours"
          (click)="handleColourClick(colour.name)"
          [routerLink]="['/shop', selectedTypedSidebar.type]">
        {{ colour.name }}
      </li>
    </ul>

    <ul>
      <div class="section-header">Shop by Size</div>
      <li *ngFor="let size of selectedTypedSidebar.sizes"
          (click)="handleSizeClick(size.name)"
          [routerLink]="['/shop', selectedTypedSidebar.type]">
        {{ size.name }}
      </li>
    </ul>
  </div>
</p-sidebar>
