<div id="basket-summary">
  <p>Shipping and additional costs are calculated based on values you have entered.</p>

  <h3>Subtotal: £{{ basketService.getSubtotal() }}</h3>

  <div>
    <p-floatLabel>
    <span class="p-input-icon-right">
      <i class="pi pi-truck"></i>
      <input type="text" pInputText [(ngModel)]="postcode" placeholder="Postcode"/>
    </span>
    </p-floatLabel>
    <p class="validation-error" *ngIf="validPostcode === false">Please enter a valid postcode.</p>
  </div>


  <p-button
    [rounded]="true"
    (click)="checkout()"
    icon="pi pi-lock"
    label="Secure Checkout"
    severity="success"
    [loading]="loading"
    [disabled]="basketService.loading || !basketService.basket.products.length">
  </p-button>
</div>
