import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ProductDTO} from "../models/dtos/product.dto";
import {UserDTO} from "../models/dtos/user.dto";
import {ProductService} from "./product.service";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient) { }

  headers = new HttpHeaders({
    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
  })

  updateProductImages(event: any, productIds: Array<string>) {
    let formData:FormData = new FormData();

    event.files.forEach((file: File) => {
      formData.append('file', file);
    });
    formData.append('productIds', JSON.stringify(productIds))

    return this.http.post<Array<ProductDTO>>('admin/image-uploader', formData, { headers: this.headers });
  }

  getImportTemplate() {
    return this.http.get<any>('admin/import', { responseType: 'blob' as 'json', headers: this.headers });
  }

  importProducts(event: any) {
    let formData:FormData = new FormData();
    formData.append('file', event.files[0]);

    return this.http.post<Array<ProductDTO>>('admin/import', formData, { headers: this.headers });
  }

  deleteProducts() {
    return this.http.delete('admin', { headers: this.headers });
  }
}
