import {NgIf} from "@angular/common";
import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {MessageService} from "primeng/api";
import {Button} from "primeng/button";
import {BasketProductDTO} from "../../models/dtos/basket.dto";
import {BasketService} from "../../services/basket.service";

@Component({
  selector: 'app-basket-product',
  standalone: true,
  imports: [
    Button,
    RouterLink,
    NgIf
  ],
  templateUrl: './basket-product.component.html',
  styleUrl: './basket-product.component.scss'
})
export class BasketProductComponent {
  @Input() basketProduct!: BasketProductDTO
  @Input() actions: boolean = true;

  constructor(public basketService: BasketService, private messageService: MessageService) {}

  getSubtotal() {
    // @ts-ignore
    return ((this.basketProduct.product.default_price.unit_amount * this.basketProduct.quantity) / 100).toFixed(2)
  }

  removeFromBasket() {
    this.basketService.removeFromBasket(this.basketProduct).subscribe(res => {
      this.basketService.basket = res;
      this.basketService.loading = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Product removed from basket.' })
    })
  }
}
