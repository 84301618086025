<div id="underlay-addon">
  <div *ngFor="let underlay of underlays" class="underlay">
    <div class="underlay-details">
      <img [src]="underlay.images[0]" [alt]="underlay.name" />
      <div>
        <p>{{ underlay.name }}</p>
        <p>15&#13217;</p>
      </div>
    </div>

    <p-button (click)="addToBasket(underlay)"
              [rounded]="true"
              icon="pi pi-shopping-bag"
              severity="danger"
              [disabled]="basketService.loading"
    >
    </p-button>
  </div>
</div>
