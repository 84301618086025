<div id="googleReviews"></div>

<div id="customer-reviews">
  <app-heading text="Happy customers" colour="white"></app-heading>

  <div class="reviews-container" *ngIf="reviews.length > 0">
    <div *ngFor="let review of reviews" class="review-container">
      <div class="rating">
        <i *ngFor="let rating of getRatingArray(review.rating)" class="pi pi-star-fill"></i>
      </div>

      <div class="reviewer">
        <p><strong>{{ review.author_name }}</strong></p>
        <p class="reviewed-date">{{ review.relative_time_description }}</p>
      </div>

      <div class="message">
        <p>{{ review.text }}</p>
      </div>
    </div>
  </div>
</div>
