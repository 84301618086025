<div id="basket">
  <div class="products-container">
    <h3>Your Basket</h3>

    <p *ngIf="!basketService.basket?.products.length">Your basket is empty.</p>

    <app-basket-product
      *ngFor="let basketProduct of basketService.basket?.products"
      [basketProduct]="basketProduct">
    </app-basket-product>
  </div>

  <div class="addons-container">
    <h3>Addons</h3>
    <app-basket-underlay-addon
      *ngFor="let underlay of underlays"
      [underlay]="underlay">
    </app-basket-underlay-addon>
  </div>

  <div class="summary-container">
    <h3>Summary</h3>
    <app-basket-summary></app-basket-summary>
  </div>
</div>
