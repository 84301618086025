import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Router} from "@angular/router";
import {AutoCompleteModule} from "primeng/autocomplete";
import {DropdownProduct} from "../../../../models/dtos/product.dto";
import {ProductService} from "../../../../services/product.service";
import {SentryService} from "../../../../services/sentry.service";

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

interface AutoCompleteSelectEvent {
  originalEvent: Event;
  value: DropdownProduct;
}

@Component({
  selector: 'app-header-product-search',
  standalone: true,
  imports: [
    AutoCompleteModule,
    FormsModule
  ],
  templateUrl: './header-product-search.component.html',
  styleUrl: './header-product-search.component.scss'
})
export class HeaderProductSearchComponent {
  dropdownProduct!: DropdownProduct;
  suggestions: Array<DropdownProduct> = [];

  constructor(public productService: ProductService,
              private router: Router,
              private sentryService: SentryService
  ) {
  }

  searchProducts(event: AutoCompleteCompleteEvent) {
    const colouredProducts = this.productService.getColouredProducts();

    const dropdownProducts: Array<DropdownProduct> = colouredProducts.map(x => ({
      label: `${x.sizes[0].product.name} ${x.sizes[0].product.metadata.colour}`,
      product: x.sizes[0].product
    }))

    this.suggestions = Array.from(
      dropdownProducts
        .filter(x => (x.label.toLowerCase()).includes(event.query.toLowerCase()))
    );
  }

  onSelect(event: AutoCompleteSelectEvent) {
    this.router.navigate(['/product', event.value.product.id], { replaceUrl: true })

    this.sentryService.logSet('header_product_search', `${event.value.product.name} ${event.value.product.metadata.colour}`);
  }
}
