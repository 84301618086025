import {NgIf} from "@angular/common";
import { Component } from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {DialogService} from "primeng/dynamicdialog";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {BasketDeliveryContactComponent} from "../../dialogs/basket-delivery-contact/basket-delivery-contact.component";
import {BasketService} from "../../services/basket.service";
import {CheckoutService} from "../../services/checkout.service";
import {StripeService} from "../../services/stripe.service";

@Component({
  selector: 'app-basket-summary',
  standalone: true,
  imports: [
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    Button,
    DialogModule,
    NgIf,
    InputTextareaModule
  ],
  templateUrl: './basket-summary.component.html',
  styleUrl: './basket-summary.component.scss'
})
export class BasketSummaryComponent {
  loading = false;

  postcode: string = '';
  validPostcode: boolean | null = null;
  deliverablePostcode: boolean | null = null;
  postcodeDialogOpen = false;

  constructor(public basketService: BasketService,
              private checkoutService: CheckoutService,
              private stripeService: StripeService,
              private dialogService: DialogService,
  ) {}

  checkout() {
    this.validatePostcode();

    if (this.validPostcode && this.deliverablePostcode) {
      this.loading = true;

      this.checkoutService.checkout(true).subscribe(res => {
        this.stripeService.redirectToCheckout(res);
      });
    }
  }

  validatePostcode() {
    const pattern = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;
    const allowedPostcodes = [
      'BD',
      'LS',
      'HD',
      'HX',
      'WF'
    ]

    const isValidPattern = pattern.test(this.postcode);

    if (isValidPattern) {
      let isAllowed = false;

      for (let allowedPostcode of allowedPostcodes) {
        isAllowed = this.postcode.toLowerCase().startsWith(allowedPostcode.toLowerCase());
        if (isAllowed) {
          break;
        }
      }

      if (isAllowed) {
        this.deliverablePostcode = true;
      }
      else {
        this.deliverablePostcode = false;
        this.showDialog()
      }

      this.validPostcode = true;
    }
    else {
      this.validPostcode = false;
    }
  }

  showDialog() {
    this.dialogService.open(BasketDeliveryContactComponent, {
      header: "Sorry about this, we don't yet deliver to your area!",
      width: '380px',
      data: this.postcode
    })
  }
}
