import { Injectable } from '@angular/core';
import {Stripe as StripeJS, loadStripe} from "@stripe/stripe-js";
import {Stripe} from "stripe";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private readonly stripe: Promise<StripeJS | null>;

  constructor() {
    this.stripe = loadStripe(environment.stripePublishableKey);
  }

  async redirectToCheckout(session: Stripe.Checkout.Session) {
    const stripe = await this.stripe;
    await stripe?.redirectToCheckout({ sessionId: session.id })
  }
}
