import { Component } from '@angular/core';
import {Button} from "primeng/button";
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-admin-development-panel',
  standalone: true,
  imports: [
    Button
  ],
  templateUrl: './admin-development-panel.component.html',
  styleUrl: './admin-development-panel.component.scss'
})
export class AdminDevelopmentPanelComponent {
  loading = false;

  constructor(private adminService: AdminService) {
  }

  deleteProducts() {
    this.loading = true;

    this.adminService.deleteProducts().subscribe(res => {
      this.loading = false;
    })
  }
}
