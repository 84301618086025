import {NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DividerModule} from "primeng/divider";
import {PaginatorModule, PaginatorState} from "primeng/paginator";
import {ProductType} from "../../models/dtos/product.dto";
import {ColouredProduct} from "../../models/dtos/typed-product.dto";
import {ApplicationStateService} from "../../services/application-state.service";
import {FilterService} from "../../services/filter.service";
import {ProductService} from "../../services/product.service";
import {HeadingComponent} from "../../shared/heading/heading.component";
import {ProductCardComponent} from "../../shared/product-card/product-card.component";
import capitalize from "../../utils/capitalize";
import {ProductFiltersComponent} from "./components/product-filters/product-filters.component";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    ProductFiltersComponent,
    ProductCardComponent,
    NgForOf,
    NgIf,
    DividerModule,
    HeadingComponent,
    PaginatorModule
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent implements OnInit {
  header: string = '';
  first: number = 0;
  productsPerPage: number = 20;
  paginatedProducts: Array<ColouredProduct> = [];

  constructor(public applicationState: ApplicationStateService,
              public productService: ProductService,
              public filterService: FilterService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.applicationState.product_filter_type = this.route.snapshot.url?.[1]?.toString() as ProductType;
    this.applicationState.product_filter_range = this.route.snapshot.url?.[2]?.toString();

    this.header = this.route.snapshot.url?.[2]?.toString() ?? this.route.snapshot.url?.[1]?.toString() ?? capitalize(this.route.snapshot.url?.[0]?.toString());

    this.filterService.getFilteredProducts();
    this.paginatedProducts = this.filterService.filteredProducts.slice(this.first, this.productsPerPage);
  }

  onPageChange(event: PaginatorState) {
    this.first = event.first;
    this.productsPerPage = event.rows;

    if (event.page === 0) {
      this.paginatedProducts = this.filterService.filteredProducts.slice(this.first, this.productsPerPage);
    }
    else {
      const firstIndex = event.page * this.productsPerPage;
      const secondIndex = firstIndex + this.productsPerPage;

      this.paginatedProducts = this.filterService.filteredProducts.slice(firstIndex, secondIndex);
    }
  }

  async handleFilterChange() {
    if (this.applicationState.product_filter_range && this.applicationState.product_filter_type) {
      await this.router.navigate(['shop', this.applicationState.product_filter_type, this.applicationState.product_filter_range])
      this.header = this.applicationState.product_filter_range;
    }
    else if (this.applicationState.product_filter_type) {
      await this.router.navigate(['shop', this.applicationState.product_filter_type])
      this.header = this.applicationState.product_filter_type;
    }
    else {
      await this.router.navigate(['shop'])
      this.header = "Shop";
    }

    this.filterService.getFilteredProducts();
    this.paginatedProducts = this.filterService.filteredProducts.slice(this.first, this.productsPerPage);
  }
}
