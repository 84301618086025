import {DatePipe, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {PrimeTemplate} from "primeng/api";
import {Button} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {DialogService} from "primeng/dynamicdialog";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {InputTextModule} from "primeng/inputtext";
import {TableModule} from "primeng/table";
import {UserCreateEditComponent} from "../../dialogs/user-create-edit/user-create-edit.component";
import {UserDTO} from "../../models/dtos/user.dto";
import {LookupService} from "../../services/lookup.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-admin-users',
  standalone: true,
  imports: [
    DatePipe,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PrimeTemplate,
    TableModule,
    Button,
    DialogModule,
    FormsModule,
    DropdownModule,
    NgIf,
    UserCreateEditComponent
  ],
  templateUrl: './admin-users.component.html',
  styleUrl: './admin-users.component.scss'
})
export class AdminUsersComponent implements OnInit {
  loading = false;

  constructor(public userService: UserService,
              public lookupService: LookupService,
              private dialogService: DialogService,
              ) {
  }

  ngOnInit() {
    this.userService.getUsers().subscribe(res => {
      this.userService.users = res;
    })
  }

  onCreate() {
    this.dialogService.open(UserCreateEditComponent, {
      header: 'Create User',
      width: '33%',
      data: {},
    })
  }

  onEdit(user: UserDTO) {
    this.dialogService.open(UserCreateEditComponent, {
      header: 'Edit User',
      width: '33%',
      data: {
        user: user,
        editing: true,
      },
    })
  }

  onDelete(user: UserDTO) {
    this.loading = true;
    
    this.userService.deleteUser(user).subscribe(res => {
      this.userService.getUsers().subscribe(res => {
        this.userService.users = res;

        this.loading = false;
      });
    })
  }
}
