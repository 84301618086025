import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Stripe} from "stripe";
import {BasketDTO} from "../models/dtos/basket.dto";
import {BasketService} from "./basket.service";

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  loading = false;

  constructor(private http: HttpClient, private basketService: BasketService) {}

  checkout(delivery: boolean) {
    this.loading = true;

    return this.http.post<Stripe.Checkout.Session>(`checkout/${this.basketService.basket.id}`, { delivery })
  }
}
