import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Stripe} from "stripe";
import {DeliveryQueryDTO} from "../models/dtos/delivery-query.dto";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendDeliveryQuery(deliveryQuery: DeliveryQueryDTO) {
    return this.http.post<Stripe.Checkout.Session>(`contact/delivery-query`, deliveryQuery)
  }
}
