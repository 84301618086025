import {Injectable, OnInit} from '@angular/core';
import {ColouredProduct} from "../models/dtos/typed-product.dto";
import uniqueArray from "../utils/unique-array";
import {ApplicationStateService} from "./application-state.service";
import {LookupService} from "./lookup.service";
import {ProductService} from "./product.service";

@Injectable({
  providedIn: 'root'
})
export class FilterService implements OnInit {
  filteredProducts: Array<ColouredProduct> = [];

  constructor(private applicationState: ApplicationStateService, private lookupService: LookupService, private productService: ProductService) { }

  ngOnInit() {
    this.getFilteredProducts()
  }

  getProductRanges() {
    return this.lookupService.lookups.productRanges.filter(x => x.type === this.applicationState.product_filter_type);
  }

  getProductColours() {
    let products = this.productService.products;

    products = products.filter(x =>
      x.metadata.type === this.applicationState.product_filter_type &&
      x.metadata.range === this.applicationState.product_filter_range
    )

    const colours = uniqueArray(products.map(x => x.metadata.colour))
    return colours.map(x => ({ name: x }))
  }

  getProductSizes() {
    let products = this.productService.products;

    products = products.filter(x =>
      x.metadata.type === this.applicationState.product_filter_type &&
      x.metadata.range === this.applicationState.product_filter_range &&
      x.metadata.colour === this.applicationState.product_filter_colour
    )

    const sizes = uniqueArray(products.map(x => x.metadata.size))
    return sizes.map(x => ({ name: x }))
  }

  getFilteredProducts() {
    let filteredProducts = this.productService.getColouredProducts()

    if (this.applicationState.product_filter_type) {
      const products = this.productService.products.filter(x =>
        x.metadata.type === this.applicationState.product_filter_type
      );

      filteredProducts = filteredProducts.filter(product =>
        product.sizes.some(size => products.map(product => product.id).includes(size.product.id))
      );
    }

    if (this.applicationState.product_filter_range) {
      const products = this.productService.products.filter(x =>
        x.metadata.range === this.applicationState.product_filter_range
      );

      filteredProducts = filteredProducts.filter(product =>
        product.sizes.some(size => products.map(product => product.id).includes(size.product.id))
      );
    }

    if (this.applicationState.product_filter_colour) {
      const products = this.productService.products.filter(x =>
        x.metadata.colour === this.applicationState.product_filter_colour
      );

      filteredProducts = filteredProducts.filter(product =>
        product.sizes.some(size => products.map(product => product.id).includes(size.product.id))
      );
    }

    if (this.applicationState.product_filter_size) {
      const products = this.productService.products.filter(x =>
        x.metadata.size === this.applicationState.product_filter_size
      );

      filteredProducts = filteredProducts.filter(product =>
        product.sizes.some(size => products.map(product => product.id).includes(size.product.id))
      );
    }

    this.filteredProducts = filteredProducts;
  }

  resetFilters() {
    this.applicationState.product_filter_type = null;
    this.applicationState.product_filter_range = null;
    this.applicationState.product_filter_colour = null;
    this.applicationState.product_filter_size = null;
  }
}
