import { Injectable } from '@angular/core';
import {Dropdown} from "../models/dropdown";
import {ProductType} from "../models/dtos/product.dto";

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {
  product_filter_type: ProductType | null = null;
  product_filter_range: string | null = null;
  product_filter_colour: string | null = null;
  product_filter_size: string | null = null;

  active_dropdown: Dropdown | null = null;

  admin_active_menu_item = "Directory";
  admin_active_type_tab = 0;

  constructor() { }
}
