import {NgIf} from "@angular/common";
import {Component, Input, OnInit} from '@angular/core';
import {Button} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {UserDTO} from "../../models/dtos/user.dto";
import {LookupService} from "../../services/lookup.service";
import {UserService} from "../../services/user.service";
import bcrypt from 'bcryptjs';

@Component({
  selector: 'app-user-create-edit',
  standalone: true,
    imports: [
        Button,
        DialogModule,
        DropdownModule,
        InputTextModule,
        NgIf,
        PaginatorModule
    ],
  templateUrl: './user-create-edit.component.html',
  styleUrl: './user-create-edit.component.scss'
})
export class UserCreateEditComponent implements OnInit {
  user: UserDTO ;
  editing = false;

  loading = false;
  password: string = '';

  constructor(public lookupService: LookupService,
              private userService: UserService,
              private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef
  ) {
  }

  ngOnInit() {
    this.user = this.dialogConfig.data.user ?? { name: '', email: '', roleId: 0 };
    this.editing = this.dialogConfig.data.editing;
  }

  async save() {
    this.loading = true;

    this.editing ?
      this.updateUser() :
      await this.createUser()

  }

  updateUser() {
    this.userService.updateUser(this.user).subscribe(res => {
      this.userService.getUsers().subscribe(res => {
        this.userService.users = res;

        this.loading = false;
        this.dialogRef.close();
      });
    })
  }

  async createUser() {
    const hashedPassword = await bcrypt.hash(this.password, 12);

    this.userService.createUser({ ...this.user, password: hashedPassword }).subscribe(res => {
      this.userService.getUsers().subscribe(res => {
        this.userService.users = res;

        this.loading = false;
        this.dialogRef.close();
      });
    })
  }
}
