<p-table
  #userTable
  [value]="userService.users"
  [tableStyle]="{ width: '100%', height: '100%' }"
  [paginator]="true"
  [rows]="50"
  [rowsPerPageOptions]="[20, 50, 100]"
  [globalFilterFields]="['id', 'name', 'email']"
>
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <p-iconField iconPosition="left" class="ml-auto">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input
          pInputText
          type="text"
          (input)="userTable.filterGlobal($event.target?.value, 'contains')"
          placeholder="Search keyword" />
      </p-iconField>

      <div>
        <p-button  icon="pi pi-plus" label="Add" [loading]="loading" (click)="onCreate()"></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Role</th>
      <th>Created</th>
      <th>Last Updated</th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td>{{ user.name }}</td>
      <td>{{ user.email }}</td>
      <td>{{ lookupService.getUserRole(user.roleId).name }}</td>
      <td>{{ user.createdAt | date: 'dd MMM yyyy' }}</td>
      <td>{{ user.updatedAt | date: 'dd MMM yyyy' }}</td>
      <td>
        <p-button styleClass="p-button-text p-button-sm" [loading]="loading" icon="pi pi-pencil" (click)="onEdit(user)"></p-button>
        <p-button styleClass="p-button-text p-button-sm" [loading]="loading" icon="pi pi-trash" severity="danger" (click)="onDelete(user)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
