import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {PaginatorModule} from "primeng/paginator";
import {DeliveryQueryDTO} from "../../models/dtos/delivery-query.dto";
import {BasketService} from "../../services/basket.service";
import {CheckoutService} from "../../services/checkout.service";
import {ContactService} from "../../services/contact.service";
import {StripeService} from "../../services/stripe.service";

@Component({
  selector: 'app-basket-delivery-contact',
  standalone: true,
  imports: [
    FormsModule,
    PaginatorModule,
    Button,
    InputTextModule,
    InputTextareaModule
  ],
  templateUrl: './basket-delivery-contact.component.html',
  styleUrl: './basket-delivery-contact.component.scss'
})
export class BasketDeliveryContactComponent implements OnInit {
  loading = false;
  query = new DeliveryQueryDTO();

  constructor(private checkoutService: CheckoutService,
              private basketService: BasketService,
              private stripeService: StripeService,
              private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private contactService: ContactService
              ) {
  }

  ngOnInit() {
    if (this.dialogConfig.data) {
      this.query.postcode = this.dialogConfig.data;
    }

    this.query.basketProducts = this.basketService.basket.products;
  }

  checkout() {
    this.loading = true;

    this.checkoutService.checkout(false).subscribe(res => {
      this.stripeService.redirectToCheckout(res);
    });
  }

  submit() {
    this.contactService.sendDeliveryQuery(this.query).subscribe();
    this.dialogRef.close();
  }
}
