import {NgIf} from "@angular/common";
import { Component } from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {DividerModule} from "primeng/divider";
import metadata from "../../constants/metadata";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink,
    DividerModule,
    NgIf
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  protected readonly metadata = metadata;
}
