import {NgForOf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {MessageService} from "primeng/api";
import {Button} from "primeng/button";
import {ProductDTO} from "../../../../models/dtos/product.dto";
import {BasketService} from "../../../../services/basket.service";
import {ProductService} from "../../../../services/product.service";

@Component({
  selector: 'app-underlay-addon',
  standalone: true,
  imports: [
    NgForOf,
    Button
  ],
  templateUrl: './underlay-addon.component.html',
  styleUrl: './underlay-addon.component.scss'
})
export class UnderlayAddonComponent implements OnInit {
  underlays: Array<ProductDTO> = [];

  constructor(public basketService: BasketService,
              private productService: ProductService,
              private messageService: MessageService
  ) {}

  ngOnInit() {
    this.underlays = this.productService.getProductsByType('Underlay');
  }

  addToBasket(underlay: ProductDTO) {
    this.basketService.addToBasket({ product: underlay, quantity: 1 }).subscribe(res => {
      this.basketService.basket = res;
      this.basketService.loading = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Underlay added to basket.' })
    })
  }
}
