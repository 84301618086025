<div id="footer-contact-bar">
  <p-divider align="center"><b>Contact Us</b></p-divider>

  <div class="contact-methods">
    <div>
      <i class="pi pi-map-marker"></i>
      <a [href]="metadata.mapsLink" target="_blank">Shop location</a>
      <p>Get directions</p>
    </div>
    <div>
      <i class="pi pi-phone"></i>
      <a [href]="'tel:' + metadata.tel">{{ metadata.telSpaced }}</a>
      <p>Call us directly</p>
    </div>
    <div>
      <i class="pi pi-envelope"></i>
      <a [href]="'mailto:' + metadata.email">{{ metadata.email }}</a>
      <p>Email us directly</p>
    </div>
  </div>
</div>

<div id="footer-content-bar">
  <div class="navigation">
      <div>
        <h4>About</h4>
        <a [routerLink]="['/about-us']">About Us</a>
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
        <a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a>
      </div>

      <div>
        <h4>Opening Times</h4>
        <a>Monday-Saturday: 09:00-18:00</a>
      </div>
  </div>

  <img src="assets/logo_rectangle.png" alt="RollEndShop.co.uk" [routerLink]="['/']"/>
</div>

<div id="footer-copyright-bar">
  Copyright © 2024 RollEndShop.co.uk - <a [routerLink]="['/dashboard']">A</a>ll Rights Reserved.
</div>
