import {NgForOf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BasketProductComponent} from "../../basket/basket-product/basket-product.component";
import {OrderDTO} from "../../models/dtos/order.dto";
import {BasketService} from "../../services/basket.service";
import {OrderService} from "../../services/order.service";
import {HeadingComponent} from "../../shared/heading/heading.component";

@Component({
  selector: 'app-order-success',
  standalone: true,
  imports: [
    HeadingComponent,
    BasketProductComponent,
    NgForOf
  ],
  templateUrl: './order-success.component.html',
  styleUrl: './order-success.component.scss'
})
export class OrderSuccessComponent implements OnInit {
  order!: OrderDTO;

  constructor(private route: ActivatedRoute,
              private orderService: OrderService,
              private router: Router,
              private basketService: BasketService,
  ) {
  }

  ngOnInit() {
    const sessionId = this.route.snapshot.queryParams['session_id'];
    const orderId = this.route.snapshot.queryParams['order_id'];

    if (sessionId) {
      this.createOrder(sessionId);
    }

    if (orderId) {
      this.orderService.getOrder(orderId).subscribe(res => {
        this.order = res;
        this.orderService.loading = false;
      })
    }
  }

  createOrder(sessionId: string) {
    this.orderService.createOrder(sessionId).subscribe(res => {
      this.order = res;

      // Set the route
      this.router.navigate(['/order-success'], { queryParams: { order_id: res.id } })

      // Delete basket
      this.basketService.getNewBasket().subscribe(res => {
        this.basketService.basket = res;
        this.basketService.loading = false;

        this.orderService.loading = false;
      })

    })
  }

  getSubtotal() {
    const prices = this.order?.products.map(product => product.product.default_price.unit_amount)

    if (!prices) {
      return (0).toFixed(2);
    }

    let total = 0;
    for (let price of prices) {
      if (!price) {
        break;
      }

      total = total + price;
    }

    return (total / 100).toFixed(2);
  }
}
