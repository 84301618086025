import {NgIf} from "@angular/common";
import { Component } from '@angular/core';
import {LoginDTO} from "../models/dtos/login.dto";
import {ApplicationStateService} from "../services/application-state.service";
import {AuthService} from "../services/auth.service";
import {AdminDevelopmentPanelComponent} from "./admin-development-panel/admin-development-panel.component";
import {AdminDirectoryComponent} from "./admin-directory/admin-directory.component";
import {AdminImageUploaderComponent} from "./admin-image-uploader/admin-image-uploader.component";
import {AdminImportComponent} from "./admin-import/admin-import.component";
import {AdminLoginComponent} from "./admin-login/admin-login.component";
import {AdminMenuComponent} from "./admin-menu/admin-menu.component";
import {AdminOrdersComponent} from "./admin-orders/admin-orders.component";
import {AdminSizeExplorerComponent} from "./admin-size-explorer/admin-size-explorer.component";
import {AdminUsersComponent} from "./admin-users/admin-users.component";

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    AdminMenuComponent,
    AdminDirectoryComponent,
    NgIf,
    AdminImageUploaderComponent,
    AdminOrdersComponent,
    AdminImportComponent,
    AdminDevelopmentPanelComponent,
    AdminUsersComponent,
    AdminLoginComponent,
    AdminSizeExplorerComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent {
  constructor(public applicationState: ApplicationStateService, public authService: AuthService) {
  }


}
