import {CurrencyPipe, DatePipe, NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {PrimeTemplate} from "primeng/api";
import {Button} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {InputTextModule} from "primeng/inputtext";
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {BasketProductComponent} from "../../basket/basket-product/basket-product.component";
import {OrderDTO} from "../../models/dtos/order.dto";
import {LookupService} from "../../services/lookup.service";
import {OrderService} from "../../services/order.service";

@Component({
  selector: 'app-admin-orders',
  standalone: true,
  imports: [
    NgForOf,
    Button,
    CurrencyPipe,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PrimeTemplate,
    TableModule,
    DatePipe,
    BasketProductComponent,
    NgIf,
    TagModule,
    DropdownModule,
    FormsModule
  ],
  templateUrl: './admin-orders.component.html',
  styleUrl: './admin-orders.component.scss'
})
export class AdminOrdersComponent implements OnInit {
  orders: Array<OrderDTO> = [];

  constructor(private orderService: OrderService,
              public lookupService: LookupService) {
  }

  ngOnInit() {
    this.orderService.getOrders().subscribe(res => {
      this.orders = res;
      this.orderService.loading = false;
    })
  }

  getTypeSeverity(typeId: number) {
    switch (typeId) {
      case 1:
        return 'success';
      case 2:
        return 'warning';
      default:
        return 'info';
    }
  }

  getStatusSeverity(statusId: number) {
    switch (statusId) {
      case 1:
        return 'info';
      case 2:
        return 'danger';
      case 3:
        return 'secondary';
      case 4:
      case 5:
        return 'success';
      case 6:
        return 'contrast';
      case 7:
        return 'danger'
      default:
        return 'info';
    }
  }
}
