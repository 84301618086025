import {NgForOf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {ProductType} from "../../models/dtos/product.dto";
import {RangedProduct} from "../../models/dtos/typed-product.dto";
import {AdminService} from "../../services/admin.service";
import {ApplicationStateService} from "../../services/application-state.service";
import {LookupService} from "../../services/lookup.service";
import {ProductService} from "../../services/product.service";
import capitalize from "../../utils/capitalize";
import {AdminTypeBarComponent} from "../admin-type-bar/admin-type-bar.component";

@Component({
  selector: 'app-admin-size-explorer',
  standalone: true,
  imports: [
    AdminTypeBarComponent,
    NgForOf
  ],
  templateUrl: './admin-size-explorer.component.html',
  styleUrl: './admin-size-explorer.component.scss'
})
export class AdminSizeExplorerComponent implements OnInit {
  rangedProducts: Array<RangedProduct> = [];

  constructor(private applicationState: ApplicationStateService,
              public productService: ProductService,
              private lookupService: LookupService
  ) {}

  ngOnInit() {
    const type = this.lookupService.lookups.productTypes[this.applicationState.admin_active_type_tab].name as ProductType;
    this.rangedProducts = this.productService.getTypedProduct(type).ranges;
  }

  protected readonly capitalize = capitalize;
}
