<p-table
  #orderTable
  [value]="orders"
  [tableStyle]="{ width: '100%', height: '100%' }"
  [paginator]="true"
  [rows]="50"
  [rowsPerPageOptions]="[20, 50, 100]"
  [globalFilterFields]="['id']"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <p-iconField iconPosition="left" class="ml-auto">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input
          pInputText
          type="text"
          (input)="orderTable.filterGlobal($event.target?.value, 'contains')"
          placeholder="Search keyword" /> 
      </p-iconField>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th pSortableColumn="createdDate">Order Date<p-sortIcon field="createdDate" /></th>
<!--      <th>Type</th>-->
<!--      <th pSortableColumn="updatedDate">Last Updated<p-sortIcon field="updatedDate" /></th>-->
<!--      <th>Status</th>-->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr>
      <td>{{ order.id }}</td>
      <td>{{ order.createdDate | date: 'dd MMM yyyy' }}</td>
<!--      <td><p-tag [value]="lookupService.getOrderType(order.typeId).name" [severity]="getTypeSeverity(order.typeId)" /></td>-->
<!--      <td>{{ order.updatedDate | date: 'dd MMM yyyy' }}</td>-->
<!--      <td>-->
<!--        <p-dropdown-->
<!--          [(ngModel)]="order.statusId"-->
<!--          [options]="lookupService.lookups.orderStatuses"-->
<!--          optionLabel="name"-->
<!--          optionValue="id">-->
<!--          <ng-template pTemplate="selectedItem">-->
<!--            <div *ngIf="order.statusId">-->
<!--              <p-tag [value]="lookupService.getOrderStatus(order.statusId).name" [severity]="getStatusSeverity(order.statusId)" [style]="{width: '100%'}"/>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--          <ng-template let-status pTemplate="item">-->
<!--            <div>-->
<!--              <p-tag [value]="status.name" [severity]="getStatusSeverity(status.id)" [style]="{width: '100%'}" />-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </p-dropdown>-->
<!--      </td>-->
    </tr>
  </ng-template>
</p-table>
