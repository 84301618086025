import {CurrencyPipe} from "@angular/common";
import { Component } from '@angular/core';
import {Button} from "primeng/button";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {InputTextModule} from "primeng/inputtext";
import {TableModule} from "primeng/table";
import {TooltipModule} from "primeng/tooltip";
import {ProductDTO} from "../../models/dtos/product.dto";
import {ProductService} from "../../services/product.service";

@Component({
  selector: 'app-admin-directory',
  standalone: true,
  imports: [
    TableModule,
    CurrencyPipe,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    Button,
    TooltipModule
  ],
  templateUrl: './admin-directory.component.html',
  styleUrl: './admin-directory.component.scss'
})
export class AdminDirectoryComponent {
  constructor(public productService: ProductService) {
  }

  onViewClick(product: ProductDTO) {
    const url = `https://dashboard.stripe.com/products/${product.id}`
    window.open(url, '_blank');
  }
}
