import {BasketProductDTO} from "./basket.dto";

export class DeliveryQueryDTO {
  name: string;
  email: string;
  telephone: string;
  postcode: string;
  message: string;
  basketProducts: Array<BasketProductDTO>;

  constructor() {
    this.name = '';
    this.telephone = '';
    this.postcode = '';
    this.message = '';
    this.basketProducts = [];
  }
}
