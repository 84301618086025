import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import {OrderDTO} from "../models/dtos/order.dto";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  loading = false;

  constructor(private http: HttpClient) {}

  getOrders() {
    this.loading = true;

    return this.http.get<Array<OrderDTO>>(`order`)
  }

  getOrder(id: string) {
    this.loading = true;

    return this.http.get<OrderDTO>(`order/${id}`)
  }

  createOrder(sessionId: string) {
    this.loading = true;

    return this.http.post<OrderDTO>(`order/${sessionId}`, {})
  }
}
