import { HttpClient } from "@angular/common/http";
import {Injectable, OnInit} from '@angular/core';
import {LoginDTO} from "../models/dtos/login.dto";
import {UserDTO} from "../models/dtos/user.dto";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  user: UserDTO | null = null;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.checkLocalToken();
  }

  login(credentials: LoginDTO) {
    return this.http.post<{ access_token: string }>('auth', credentials);
  }

  checkLocalToken() {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      const user = jwtDecode<UserDTO & { iat: number, exp: number }>(accessToken);
      const currentTime = Math.floor(Date.now() / 1000);

      if (currentTime > user.exp) {
        this.user = null;
        localStorage.removeItem('access_token');
      }
      else {
        this.user = user;
      }
    }
  }

  parseToken(accessToken: string) {
    localStorage.setItem('access_token', accessToken);
    this.user = jwtDecode<UserDTO>(accessToken);
  }
}
