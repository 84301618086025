import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {UserDTO} from "../models/dtos/user.dto";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users: Array<UserDTO> = [];

  headers = new HttpHeaders({
    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
  })

  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get<Array<UserDTO>>('user', { headers: this.headers });
  }

  updateUser(user: UserDTO) {
    return this.http.put<UserDTO>('user', user, { headers: this.headers });
  }

  createUser(user: UserDTO & { password: string }) {
    return this.http.post<UserDTO>('user', user, { headers: this.headers });
  }

  deleteUser(user: UserDTO) {
    return this.http.delete<UserDTO>(`user/${user.id}`, { headers: this.headers });
  }
}
