import {NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {DividerModule} from "primeng/divider";
import {ApplicationStateService} from "../../../../services/application-state.service";
import {FilterService} from "../../../../services/filter.service";
import {LookupService} from "../../../../services/lookup.service";
import {ProductService} from "../../../../services/product.service";
import {Dropdown} from "../../../../models/dropdown";
import { ProductType} from "../../../../models/dtos/product.dto";
import {SentryService} from "../../../../services/sentry.service";
import capitalize from "../../../../utils/capitalize";
import {ProductCardComponent} from "../../../product-card/product-card.component";

@Component({
  selector: 'app-header-dropdowns',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    RouterLink,
    ProductCardComponent,
    DividerModule
  ],
  templateUrl: './header-dropdowns.component.html',
  styleUrl: './header-dropdowns.component.scss'
})
export class HeaderDropdownsComponent implements OnInit {
  dropdowns: Array<Dropdown> = [];

  constructor(public applicationState: ApplicationStateService,
              public productService: ProductService,
              private lookupService: LookupService,
              public filterService: FilterService,
              private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.onLoad();
  }

  async onLoad() {
    await this.initialiseDropdowns();
  }

  async initialiseDropdowns() {
    const excludedTypes: Array<ProductType> = ['Underlay'];

    for (let type of (this.lookupService.lookups.productTypes.map(x => x.name as ProductType))) {
      if (excludedTypes.includes(type)) {
        break;
      }

      const ranges = this.lookupService.getProductRangesByType(type);
      const colours = this.lookupService.getProductColoursByType(type);
      const sizes = this.lookupService.getProductSizesByType(type);
      const product = this.productService.getTypedProduct(type).ranges[0].colours[0];

      this.dropdowns.push(new Dropdown(type as ProductType, ranges, colours, sizes, product));
    }
  }

  handleDropdownHover(dropdown: Dropdown | null) {
    this.applicationState.active_dropdown = dropdown;
  }

  handleTypeClick(type: ProductType) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_type = type;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()

    this.sentryService.logSet('header_type_click', type)
  }

  handleRangeClick(range: string) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_range = range;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()

    this.sentryService.logSet('header_range_click', range)
  }

  handleColourClick(colour: string) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_colour = colour;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()

    this.sentryService.logSet('header_colour_click', colour)
  }

  handleSizeClick(size: string) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_size = size;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()

    this.sentryService.logSet('header_size_click', size)
  }

  protected readonly capitalize = capitalize;
}
