<a [routerLink]="['/shop', 'Carpet', range.name]" id="range-card" (click)="onClick()">
  <div class="image-container"
       (mouseenter)="setHovering(true)"
       (mouseleave)="setHovering(false)">
    <div *ngIf="isHovering" class="hover-overlay">
      <div class="overlay-content">
        <div class="overlay-background"></div>
        <p-button
          class="view-range-button"
          [rounded]="true"
          label="View Range"
          severity="danger">
        </p-button>
      </div>
    </div>

    <img [src]="range.image" [alt]="range.name" class="range-image" />
  </div>
  <div class="range-info">
    <p>{{ capitalize(range.name) }}</p>
  </div>
</a>
