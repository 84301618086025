<div id="location">
  <div>
    <app-heading text="Our location"></app-heading>

    <div class="address-container">
      <p>{{ metadata.address.split(',')[0] }}</p>
      <p>{{ metadata.address.split(',')[1] }}</p>
      <p>{{ metadata.address.split(',')[2] }}</p>
      <p>{{ metadata.address.split(',')[3] }}</p>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th>Open</th>
          <th>Close</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let openingTime of openingTimes">
          <td>{{ capitalize(openingTime.day) }}</td>
          <td>{{ openingTime.open }}</td>
          <td>{{ openingTime.close }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>
    <google-map
      height="500px"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options"
    ></google-map>
  </div>
</div>

