<div id="dialog-container">
  Don’t worry, we could still cover your area for delivery! Please fill out the form and a
  member of our team will be in touch with you to get your delivery organised!

  <form #contactForm="ngForm">
    <input pInputText name="name" [(ngModel)]="query.name" autocomplete="off" placeholder="Name"/>
    <input pInputText name="email" [(ngModel)]="query.email" autocomplete="off" type="email" placeholder="Email"/>
    <input pInputText name="phone" [(ngModel)]="query.telephone" autocomplete="off" type="tel" placeholder="Telephone"/>
    <input pInputText [disabled]="true" name="postcode" [(ngModel)]="query.postcode" autocomplete="off" placeholder="Postcode"/>
    <textarea  pInputTextarea name="message" [(ngModel)]="query.message" autocomplete="off" rows="5" placeholder="Message"></textarea>
  </form>

  <div class="button-section">
    <p-button label="I want to collect" [loading]="loading" severity="warning" (click)="checkout()" />
    <p-button label="Submit message" [disabled]="loading" severity="success" (click)="submit()" />
  </div>
</div>
