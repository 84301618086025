import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import * as Sentry from "@sentry/angular";
import { AppComponent } from './app/app.component';

Sentry.init({
  dsn: "https://1d57f69170ff3766f7c4130f53ebf56e@o4507593501114368.ingest.de.sentry.io/4507593564291152",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://api.rollendshop.co.uk"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
