import {NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {BasketService} from "../services/basket.service";
import {ProductService} from "../services/product.service";
import {BasketProductComponent} from "./basket-product/basket-product.component";
import { ProductDTO } from "../models/dtos/product.dto";
import {BasketSummaryComponent} from "./basket-summary/basket-summary.component";
import {BasketUnderlayAddonComponent} from "./basket-underlay-addon/basket-underlay-addon.component";

@Component({
  selector: 'app-basket',
  standalone: true,
  imports: [
    BasketProductComponent,
    NgForOf,
    BasketUnderlayAddonComponent,
    BasketSummaryComponent,
    NgIf
  ],
  templateUrl: './basket.component.html',
  styleUrl: './basket.component.scss'
})
export class BasketComponent implements OnInit {
  underlays: Array<ProductDTO> = [];

  constructor(public basketService: BasketService, private productService: ProductService) {}

  ngOnInit() {
    this.underlays = this.productService.getProductsByType('Underlay');
  }
}
