import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {AutoCompleteModule} from "primeng/autocomplete";
import {Button} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {SidebarModule} from "primeng/sidebar";
import metadata from "../../constants/metadata";
import {Dropdown} from "../../models/dropdown";
import {ProductType} from "../../models/dtos/product.dto";
import {ApplicationStateService} from "../../services/application-state.service";
import {BasketService} from "../../services/basket.service";
import {FilterService} from "../../services/filter.service";
import {LookupService} from "../../services/lookup.service";
import {ProductService} from "../../services/product.service";
import {SentryService} from "../../services/sentry.service";
import {HeaderAddressComponent} from "./components/header-address/header-address.component";
import {HeaderDropdownsComponent} from "./components/header-dropdowns/header-dropdowns.component";
import {HeaderProductSearchComponent} from "./components/header-product-search/header-product-search.component";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    InputTextModule,
    FormsModule,
    HeaderAddressComponent,
    Button,
    HeaderDropdownsComponent,
    RouterLink,
    AutoCompleteModule,
    HeaderProductSearchComponent,
    NgIf,
    SidebarModule,
    NgForOf,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  protected readonly metadata = metadata;

  sidebarVisible: boolean = false;
  typedSidebarVisible: boolean = false;
  typedSidebars: Array<Dropdown> = [];
  selectedTypedSidebar: Dropdown = null;
  suggestions: Array<string> = [];

  constructor(public applicationState: ApplicationStateService,
              public basketService: BasketService,
              private productService: ProductService,
              private lookupService: LookupService,
              private filterService: FilterService,
              private sentryService: SentryService
  ) {
  }

  ngOnInit() {
    this.onLoad()
  }

  async onLoad() {
    this.suggestions = this.productService.products.map(x => `${x.name} ${x.metadata.colour}`);
    await this.initialiseSidebars()
  }

  async initialiseSidebars() {
    const excludedTypes: Array<ProductType> = ['Underlay'];

    for (let type of (this.lookupService.lookups.productTypes.map(x => x.name as ProductType))) {
      if (excludedTypes.includes(type)) {
        break;
      }

      const ranges = this.lookupService.getProductRangesByType(type);
      const colours = this.lookupService.getProductColoursByType(type);
      const sizes = this.lookupService.getProductSizesByType(type);
      const product = this.productService.getTypedProduct(type).ranges[0].colours[0];

      this.typedSidebars.push(new Dropdown(type as ProductType, ranges, colours, sizes, product));
    }
  }

  openTypedSidebar(sidebar: Dropdown) {
    this.selectedTypedSidebar = sidebar;
    this.typedSidebarVisible = true;
  }

  handleRangeClick(range: string) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_range = range;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()
    this.typedSidebarVisible = false;
    this.sidebarVisible = false;

    this.sentryService.logSet('header_range_click', range)
  }

  handleColourClick(colour: string) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_colour = colour;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()
    this.typedSidebarVisible = false;
    this.sidebarVisible = false;

    this.sentryService.logSet('header_colour_click', colour)
  }

  handleSizeClick(size: string) {
    this.filterService.resetFilters();
    this.applicationState.product_filter_size = size;
    this.applicationState.active_dropdown = null;
    this.filterService.getFilteredProducts()
    this.typedSidebarVisible = false;
    this.sidebarVisible = false;

    this.sentryService.logSet('header_size_click', size)
  }
}
