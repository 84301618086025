import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {DividerModule} from "primeng/divider";
import {Menu, MenuItem, MenuSection} from "../../models/menu";
import {ApplicationStateService} from "../../services/application-state.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-admin-menu',
  standalone: true,
  imports: [
    DividerModule,
    NgForOf,
    NgClass,
    NgIf
  ],
  templateUrl: './admin-menu.component.html',
  styleUrl: './admin-menu.component.scss'
})
export class AdminMenuComponent implements OnInit {
  menu: Menu;

  constructor(public applicationState: ApplicationStateService,
              public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.menu = {
      sections: [
        {
          label: 'Product Management',
          icon: 'pi-box',
          items: [
            { label: 'Directory', roleId: 1 },
            { label: 'Image Uploader', roleId: 2 },
            { label: 'Range Editor', disabled: true, roleId: 2 },
            { label: 'Invalid Products', disabled: true , roleId: 1 },
            { label: 'Size Explorer', roleId: 1 },
            { label: 'Import', roleId: 2 },
          ],
          roleId: 1,
        },
        {
          label: 'Order Management',
          icon: 'pi-truck',
          items: [
            { label: 'Orders', roleId: 1 }
          ],
          roleId: 1,
        },
        {
          label: 'User Management',
          icon: 'pi-user',
          items: [
            { label: 'Users', roleId: 2 },
            { label: 'Customers', disabled: true, roleId: 2 },
          ],
          roleId: 2,
        },
        {
          label: 'Development',
          icon: 'pi-code',
          items: [
            { label: 'Development Panel', roleId: 3 }
          ],
          roleId: 3
        }
      ]
    }
  }

  getAllowedSections(sections: Array<MenuSection>) {
    return sections.filter(x => x.roleId <= this.authService.user.roleId)
  }

  getAllowedItems(items: Array<MenuItem>) {
    return items.filter(x => x.roleId <= this.authService.user.roleId)
  }
}
