import {NgIf} from "@angular/common";
import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {PrimeTemplate} from "primeng/api";
import {Button} from "primeng/button";
import {CarouselModule} from "primeng/carousel";
import {ColouredProduct, RangedProduct} from "../../models/dtos/typed-product.dto";
import {ProductService} from "../../services/product.service";
import {ProductDTO} from "../../models/dtos/product.dto";
import capitalize from "../../utils/capitalize";

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [
    Button,
    NgIf,
    RouterLink,
    CarouselModule,
    PrimeTemplate
  ],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent implements OnInit {
  protected readonly capitalize = capitalize;

  @Input() colouredProduct!: ColouredProduct;
  @Input() selectedProduct?: ProductDTO;
  rangedProduct: RangedProduct | undefined;

  responsiveOptions = [];

  constructor(public productService: ProductService) {

  }

  ngOnInit() {
    if (!this.selectedProduct) {
      this.selectedProduct = this.colouredProduct?.sizes[0].product;
    }

    if (this.selectedProduct) {
      this.rangedProduct = this.productService.getRangedProductByProductId(this.selectedProduct.id)
    }

    this.responsiveOptions = [
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
      }
    ];
  }

  handleColourClick(product: ProductDTO) {
    this.selectedProduct = product;
  }

  checkShowColours() {
    return this.rangedProduct && this.rangedProduct?.colours?.length > 1;
  }
}
