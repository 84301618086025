<div id="basket-underlay-addon">
  <div class="details-container">
    <img [src]="underlay.images[0]" [alt]="underlay.name" />

    <div>
      <p>{{ underlay.name }}</p>

      <p class="small-detail">Size: </p>
      <p class="small-detail">£{{ productService.getPrice(underlay) }}</p>

    </div>

  </div>

  <p-button styleClass="p-button-text p-button-sm" class="small-detail" [disabled]="basketService.loading" (click)="addToBasket(underlay)">Add</p-button>
</div>
