import {ProductTypeLookupDTO} from "./dtos/lookup.dto";
import {ColouredProduct} from "./dtos/typed-product.dto";
import {ProductType} from "./dtos/product.dto";

export class Dropdown {
  type: ProductType
  ranges: Array<ProductTypeLookupDTO>;
  colours: Array<ProductTypeLookupDTO>;
  sizes: Array<ProductTypeLookupDTO>;
  product: ColouredProduct;

  constructor(type: ProductType,
              ranges: Array<ProductTypeLookupDTO>,
              colours: Array<ProductTypeLookupDTO>,
              sizes: Array<ProductTypeLookupDTO>,
              product: ColouredProduct,
  ) {
    this.type = type;
    this.ranges = ranges;
    this.colours = colours;
    this.sizes = sizes;
    this.product = product;
  }
}
