import {Component, EventEmitter, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {ApplicationStateService} from "../../../../services/application-state.service";
import {FilterService} from "../../../../services/filter.service";
import {LookupService} from "../../../../services/lookup.service";

@Component({
  selector: 'app-product-filters',
  standalone: true,
  imports: [
    FormsModule,
    DropdownModule,
  ],
  templateUrl: './product-filters.component.html',
  styleUrl: './product-filters.component.scss'
})
export class ProductFiltersComponent {
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public applicationState: ApplicationStateService,
              public lookupService: LookupService,
              public filterService: FilterService) {}

  handleFilterChange() {
    this.onFilterChange.emit();
  }
}
