<div id="admin-login">
  <div class="panel">
    <img src="assets/logo_rectangle.png"
         alt="RollEndShop.co.uk"
    />

    <div class="heading">
      <h1>Administration</h1>

      <p>Sign in to continue access</p>
    </div>

    <a [routerLink]="['/']">www.RollEndShop.co.uk</a>
  </div>

  <div class="panel">
    <div>
      <h1>Sign In</h1>

      <form>
        <div>
          <label for="email">Email</label>
          <input pInputText name="email" id="email" autocomplete="off" [(ngModel)]="credentials.email" />
        </div>

        <div>
          <label for="password">Password</label>
          <input pInputText name="password" id="password" autocomplete="off" type="password" [(ngModel)]="credentials.password" />
        </div>

        <small class="validation-error">{{ error }}</small>

        <div class="button-section">
          <p-button label="Login" icon="pi pi-sign-in" severity="success" [loading]="loading" (click)="login()" />
        </div>
      </form>
    </div>
  </div>
</div>
