export default function uniqueArray<T = any[]>(arr: any[]) {
  let uniqueArr: any[] = [];

  arr.forEach(item => {
    if (item === null) {
      return;
    }

    if (!uniqueArr.includes(item)) {
      uniqueArr.push(item);
    }
  });

  return uniqueArr as T;
}
