import {NgForOf} from "@angular/common";
import { Component } from '@angular/core';
import {ProductSpotlightComponent} from "../product/product-spotlight/product-spotlight.component";
import {RangeCardComponent} from "../shared/range-card/range-card.component";
import {LookupService} from "../services/lookup.service";
import {ProductService} from "../services/product.service";
import {CarpetRangesComponent} from "./carpet-ranges/carpet-ranges.component";
import {CustomerReviewsComponent} from "./customer-reviews/customer-reviews.component";
import {HeroComponent} from "./hero/hero.component";
import {LocationComponent} from "./location/location.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeroComponent,
    RangeCardComponent,
    NgForOf,
    CarpetRangesComponent,
    ProductSpotlightComponent,
    CustomerReviewsComponent,
    LocationComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  constructor(public lookupService: LookupService) {}
}
