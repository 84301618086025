import {NgForOf, NgIf} from "@angular/common";
import {AfterViewInit, Component } from '@angular/core';
import {HeadingComponent} from "../../shared/heading/heading.component";

declare const google: any;

@Component({
  selector: 'app-customer-reviews',
  standalone: true,
  imports: [
    HeadingComponent,
    NgForOf,
    NgIf
  ],
  templateUrl: './customer-reviews.component.html',
  styleUrl: './customer-reviews.component.scss'
})
export class CustomerReviewsComponent implements AfterViewInit {
  service: any;
  public reviews: any[] = [];

  constructor() {}

  ngAfterViewInit() {
    const request = {
      placeId: "ChIJmbHycUHhe0gRu3O7EK-PHds",
      fields: ['reviews']
    };
    this.service = new google.maps.places.PlacesService(document.getElementById('googleReviews'));
    this.service.getDetails(request, this.callback);
  }

  callback = (place: any, status: any) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      this.reviews = this.cleanseReviews(place.reviews.slice(0, 4));
    }
  };
  
  getRatingArray(rating: number) {
    return Array.from({length: rating})
  }

  cleanseReviews(reviews: any[]) {
    return reviews.map(review => ({
      ...review,
      text: review.text.substring(0, 300) + (review.text.length > 300 ? '...' : ''),
    }));
  }
}
