<app-page-loader *ngIf="loading"></app-page-loader>

<div *ngIf="!loading" class="app-root">
  <app-header></app-header>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="renderFooter()"></app-footer>

  <p-toast />
</div>
