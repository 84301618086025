import { Routes } from '@angular/router';
import {AdminComponent} from "./admin/admin.component";
import {BasketComponent} from "./basket/basket.component";
import {HomeComponent} from "./home/home.component";
import {PrivacyPolicyComponent} from "./home/privacy-policy/privacy-policy.component";
import {TermsAndConditionsComponent} from "./home/terms-and-conditions/terms-and-conditions.component";
import {OrderSuccessComponent} from "./order/order-success/order-success.component";
import {ProductDetailsComponent} from "./product/product-details/product-details.component";
import {ProductListComponent} from "./product/product-list/product-list.component";

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'success', component: HomeComponent },
  { path: 'shop', component: ProductListComponent },
  { path: 'shop/:type', component: ProductListComponent },
  { path: 'shop/:type/:range', component: ProductListComponent },
  { path: 'dashboard', component: AdminComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'product/:id', component: ProductDetailsComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'order-success', component: OrderSuccessComponent }
];
