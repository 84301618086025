<div id="terms-and-conditions">
  <h1>Terms and Conditions</h1>

  <ol>
    <li>
      <h2>Acceptance of Terms</h2>
      <p>By accessing or using the services provided by RollEndShop, you agree to be bound
        by these Terms and Conditions. If you do not agree with any part of these terms,
        you may not access the services.</p>
    </li>
    <li>
      <h2>Service Description</h2>
      <p>RollEndShop provides a platform for the sale of carpets. The carpets are offered
        for sale "as is" and are subject to availability.</p>
    </li>
    <li>
      <h2>Colour Accuracy Disclaimer</h2>
      <p>Please note that while we strive to display our carpets as accurately as possible on
        our website, the actual colours you see will depend on your monitor and may not
        be 100% accurate. Colours of carpets can also vary slightly due to the nature of
        the materials and dyeing process.</p>

      <div class="subsection">
        <h3>Important Points to Consider</h3>
        <p>&#x2022; <strong>Monitor Display:</strong> The colour representation on your
          screen can be
          affected by the display settings, monitor type, and lighting conditions
          in which
          you are viewing the website.</p>
        <p>&#x2022; <strong>Material Variations:</strong> Natural variations in the
          fibers and dyes used
          in the carpet manufacturing process may result in slight differences in
          colour
          from batch to batch.</p>
        <p>&#x2022; <strong>Lighting Conditions:</strong> The appearance of carpet
          colours can change
          depending on the lighting conditions in your space. We recommend viewing
          samples
          in different lighting environments to get a true sense of the colour.
        </p>
        <p>&#x2022; <strong>Samples:</strong> We highly encourage ordering a sample or
          visiting our store
          to see the carpet in person before making a purchase decision to ensure
          you are
          satisfied with the colour.</p>

        <p>By purchasing a carpet from our shop, you acknowledge and accept that slight
          colour
          variations may occur and do not constitute a defect or reason for
          return. If you
          have any questions or need further assistance, please contact our
          customer
          service team.</p>
      </div>
    </li>
    <li>
      <h2>User Conduct</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Use the services for any illegal purpose or in violation of any laws.</li>
        <li>Impersonate any person or entity.</li>
        <li>Transmit any material that is unlawful, harmful, threatening, abusive,
          harassing, defamatory, vulgar, obscene, or otherwise objectionable.
        </li>
      </ul>
    </li>
    <li>
      <h2>Intellectual Property</h2>
      <p>All content included on the RollEndShop website, such as text, graphics, logos,
        button icons, images, audio clips, and digital downloads, is the property of
        RollEndShop or its content suppliers and is protected by copyright laws.</p>
    </li>
    <li>
      <h2>Limitation of Liability</h2>
      <p>RollEndShop shall not be liable for any direct, indirect, incidental, special, or
        consequential damages arising out of or in any way connected with the use of its
        services or the purchase of its carpets.</p>
    </li>
    <li>
      <h2>Privacy Policy</h2>
      <p>Your use of RollEndShop's services is also governed by our Privacy Policy, which
        can be found on our website.</p>
    </li>
    <li>
      <h2>Governing Law</h2>
      <p>These terms and conditions shall be governed by and construed in accordance with the
        laws of [Your Jurisdiction], without regard to its conflict of law
        provisions.</p>
    </li>
    <li>
      <h2>Modifications to Terms</h2>
      <p>RollEndShop reserves the right to modify these Terms and Conditions at any time.
        Any changes will be effective immediately upon posting.</p>
    </li>
    <li>
      <h2>Termination</h2>
      <p>RollEndShop may terminate or suspend your access to its services at any time, with
        or without cause.</p>
    </li>
    <li>
      <h2>Entire Agreement</h2>
      <p>These Terms and Conditions constitute the entire agreement between you and Rollsend
        Shop regarding your use of its services.</p>
    </li>
  </ol>
</div>
