<div id="product-spotlight">
  <ul class="product-spotlight-menu">
    <li (click)="tab = 0" [ngClass]="{'active-tab': tab === 0}">Most Popular</li>
    <li (click)="tab = 1" [ngClass]="{'active-tab': tab === 1}">Top Picks</li>
    <li (click)="tab = 2" [ngClass]="{'active-tab': tab === 2}">New Stock</li>
  </ul>

  <div class="product-container" *ngIf="tab === 0">
    <app-product-card *ngFor="let colouredProduct of mostPopular" [colouredProduct]="colouredProduct"></app-product-card>
  </div>

  <div class="product-container" *ngIf="tab === 1">
    <app-product-card *ngFor="let colouredProduct of topPicks" [colouredProduct]="colouredProduct"></app-product-card>
  </div>

  <div class="product-container" *ngIf="tab === 2">
    <app-product-card *ngFor="let colouredProduct of newStock" [colouredProduct]="colouredProduct"></app-product-card>
  </div>
</div>

