import {NgClass, NgIf} from "@angular/common";
import {Component, Input} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {Button} from "primeng/button";
import {ProductTypeImageLookupDTO} from "../../models/dtos/lookup.dto";
import {SentryService} from "../../services/sentry.service";
import capitalize from "../../utils/capitalize";

@Component({
  selector: 'app-range-card',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgClass,
    Button
  ],
  templateUrl: './range-card.component.html',
  styleUrl: './range-card.component.scss'
})
export class RangeCardComponent {
  protected readonly capitalize = capitalize;

  @Input() range!: ProductTypeImageLookupDTO;
  isHovering: boolean = false;

  constructor(private sentryService: SentryService) {}

  onClick() {
    this.sentryService.logSet('range_card_click', this.range.name)
  }

  setHovering(state: boolean): void {
    this.isHovering = state;
  }
}
