import {NgClass, NgForOf} from "@angular/common";
import {Component, EventEmitter, Output} from '@angular/core';
import {ApplicationStateService} from "../../services/application-state.service";
import {LookupService} from "../../services/lookup.service";

@Component({
  selector: 'app-admin-type-bar',
  standalone: true,
  imports: [
    NgForOf,
    NgClass
  ],
  templateUrl: './admin-type-bar.component.html',
  styleUrl: './admin-type-bar.component.scss'
})
export class AdminTypeBarComponent {
  @Output() onTabClick: EventEmitter<any> = new EventEmitter();

  constructor(public applicationState: ApplicationStateService,
              public lookupService: LookupService
  ) {
  }

  onClick(index: number) {
    this.applicationState.admin_active_type_tab = index;
    this.onTabClick.emit();
  }
}
