import {NgClass} from "@angular/common";
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-heading',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './heading.component.html',
  styleUrl: './heading.component.scss'
})
export class HeadingComponent implements OnInit {
  @Input() text!: string;
  @Input() colour?: 'white' | 'black' = 'black';

  nonEmphasisedWords: string = '';
  emphasisedWord: string = '';

  ngOnInit(): void {
    const words = this.text.split(' ');
    this.emphasisedWord = words.pop() ?? '';
    this.nonEmphasisedWords = words.join(' ');
  }
}
