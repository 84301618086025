<app-admin-type-bar (onTabClick)="ngOnInit()"></app-admin-type-bar>

<div *ngFor="let rangedProduct of rangedProducts" id="admin-size-explorer">
  <h1>{{ rangedProduct.range }}</h1>

  <div class="ranged-product-container">
    <div *ngFor="let colouredProduct of rangedProduct.colours" class="product-colour-container">
      <h3>{{ colouredProduct.colour ? capitalize(colouredProduct.colour) : colouredProduct.sizes[0].product.name }}</h3>

      <ul>
        <li *ngFor="let sizedProduct of colouredProduct.sizes">
          <a [href]="'https://dashboard.stripe.com/products/' + sizedProduct.product.id" target="_blank">{{ capitalize(sizedProduct.size) }}</a>
        </li>
      </ul>
    </div>
  </div>

</div>
