import {CurrencyPipe} from "@angular/common";
import { Component } from '@angular/core';
import {PrimeTemplate} from "primeng/api";
import {Button} from "primeng/button";
import {FileUploadModule} from "primeng/fileupload";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {InputTextModule} from "primeng/inputtext";
import {TableModule} from "primeng/table";
import {ProductDTO} from "../../models/dtos/product.dto";
import { AdminService } from "../../services/admin.service";
import {ProductService} from "../../services/product.service";

@Component({
  selector: 'app-admin-import',
  standalone: true,
  imports: [
    Button,
    CurrencyPipe,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PrimeTemplate,
    TableModule,
    FileUploadModule
  ],
  templateUrl: './admin-import.component.html',
  styleUrl: './admin-import.component.scss'
})
export class AdminImportComponent {
  importedProducts: Array<ProductDTO> = [];
  loading = false;

  constructor(private adminService: AdminService,
              private productService: ProductService,
              ) {
  }

  onDownloadTemplateClick() {
    this.loading = true;

    this.adminService.getImportTemplate().subscribe(res => {
      const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Import_Template.xlsx';
      link.click();

      this.loading = false;
    })
  }

  importProducts(event: any) {
    this.loading = true;

    this.adminService.importProducts(event).subscribe(res => {
      this.importedProducts = res;

      this.productService.getProducts().subscribe(res => {
        this.productService.products = res;

        this.productService.getTypedProducts().subscribe(res => {
          this.productService.typedProducts = res;

          this.loading = false;
        })
      })
    })
  }

  onViewClick(product: ProductDTO) {
    const url = `https://dashboard.stripe.com/products/${product.id}`
    window.open(url, '_blank');
  }
}
